import React, { FC } from 'react'

import { Select } from '../../select/root'
import { Field, FieldComponentProps } from '../root/field'

type FieldTypeExtended = FieldComponentProps<string>['field'] & {
  options: Array<{
    value: string
    label: string
  }>
}

interface FieldSelectSpecificProps extends FieldComponentProps<string> {
  field: FieldTypeExtended
  isSearchable?: boolean
  inputOnlyNumbers?: boolean
}

const Component = Field<typeof Select, FieldSelectSpecificProps>()
const components = { ...Component?.defaultProps?.components, InputComponent: Select }

export const FieldSelect: FC<FieldSelectSpecificProps> = (props) => (
  <Component
    {...props}
    components={components}
  />
)
