import { DefaultTheme } from 'styled-components'

import { breakpointsTheme } from './breakpoints'
import { colorTheme } from './color'
import { fontTheme } from './font'
import { iconTheme } from './icon'
import { imageTheme } from './image'

export interface Theme extends DefaultTheme {
  color: typeof colorTheme
  font: typeof fontTheme
  icon: typeof iconTheme
  image: typeof imageTheme
  breakpoints: typeof breakpointsTheme
}

export const theme: Theme = {
  color: colorTheme,
  image: imageTheme,
  icon: iconTheme,
  font: fontTheme,
  breakpoints: breakpointsTheme
}

export * from './color'
export * from './font'
