import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { Translations } from '@jtb-don-fe/types'

import { Text } from '../../text'
import { ButtonPrimary } from '../../form'
import { Grid } from '../../layout'

import { Footer, Header, StyledModal, StyledOverlay, Main, UnorderedList, ListItem } from './cookies-modal-styled'
import { FunctionalCookiesTab } from './functional-cookies-tab'
import { AnalyticCookiesTab } from './analytic-cookies-tab'

interface CookiesModalProps {
  isOpen: boolean
  translations: Translations
  onRequestClose(): void
  onAllowCookies(): void
  onRefuseCookies(): void
  onAcceptSelection(withAnalytics: boolean): void
}

enum Tab {
  FunctionalCookies = 'functionalCookies',
  AnalyticCookies = 'analyticCookies'
}

export const CookiesModal = ({
  isOpen,
  onRequestClose,
  translations,
  onAllowCookies,
  onRefuseCookies,
  onAcceptSelection
}: CookiesModalProps) => {
  const [tab, setTab] = useState(Tab.FunctionalCookies)
  const [withAnalytics, setWithAnalytics] = useState(false)

  if (!translations) {
    return null
  }

  const isWelcome = window?.location?.pathname === '/welcome' || window?.location?.pathname === '/welcome/'

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      closeTimeoutMS={250}
      className='_'
      overlayClassName='_'
      contentElement={(props, children) => (
        <StyledModal {...props} isWelcome={isWelcome}>
          {children}
        </StyledModal>
      )}
      overlayElement={(props, contentElement) => <StyledOverlay {...props}>{contentElement}</StyledOverlay>}
    >
      <Header>
        <Text py={3} fontSize='1.5em'>
          {translations.title}
        </Text>
      </Header>

      <Main>
        <UnorderedList>
          <ListItem active={tab === Tab.FunctionalCookies} onClick={() => setTab(Tab.FunctionalCookies)}>
            {translations.tabs.functionalCookies}
          </ListItem>
          <ListItem active={tab === Tab.AnalyticCookies} onClick={() => setTab(Tab.AnalyticCookies)}>
            {translations.tabs.analyticCookies}
          </ListItem>
        </UnorderedList>
        {tab === Tab.FunctionalCookies && <FunctionalCookiesTab translations={translations.functionalCookies} />}
        {tab === Tab.AnalyticCookies && (
          <AnalyticCookiesTab
            translations={translations.analyticCookies}
            withAnalytics={withAnalytics}
            setWithAnalytics={setWithAnalytics}
          />
        )}
      </Main>

      <Footer>
        <ButtonPrimary
          width='auto'
          name='allowCookies'
          onClick={() => {
            onAllowCookies()
            onRequestClose()
          }}
        >
          {translations.button.allowCookies}
        </ButtonPrimary>
        <Grid gridGap='1.5rem' gridTemplateColumns={['100%', '1fr 1fr']} pt={['1.5rem', 0]}>
          <ButtonPrimary
            width='auto'
            name='refuseCookies'
            onClick={() => {
              onRefuseCookies()
              onRequestClose()
            }}
          >
            {translations.button.refuseCookies}
          </ButtonPrimary>
          <ButtonPrimary
            width='auto'
            name='acceptSelection'
            onClick={() => {
              onAcceptSelection(withAnalytics)
              onRequestClose()
            }}
          >
            {translations.button.acceptSelection}
          </ButtonPrimary>
        </Grid>
      </Footer>
    </ReactModal>
  )
}
