import styled from 'styled-components'

export const InfoStepsStyled = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`
