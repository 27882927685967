import React, { memo } from 'react'

import { Grid } from '../grid'

export const FormLayoutInput = memo(({ children }) => (
  <Grid
    gridTemplateColumns={['auto', '1fr 1fr']}
    gridGap={[null, '0 1.5rem']}
  >
    {children}
  </Grid>
))
