import i18next from 'i18next'
import { reactI18nextModule } from 'react-i18next'

export default i18next
  .use(reactI18nextModule)
  .init({
    fallbackLng: 'cs',
    lng: 'cs',
    keySeparator: '.',
    nsSeparator: false,
    defaultNS: 'translations',
    debug: false,
    interpolation: {
      escapeValue: false
    },
    returnObjects: true,
    react: {
      wait: true
    }
  })
