import React, { FC } from 'react'

import { Progress } from '../../header'

import { ProgressBarStroke } from './components/progress-bar-stroke'
import { ProgressBarStyled } from './components/progress-bar-styled'

interface ProgressBarProps {
  progress: Progress
}

export const ProgressBar: FC<ProgressBarProps> = ({ progress }) => {
  const totalItems = progress.items.length
  const completedItems = progress.activeIndex + 1
  const itemWidth = (100 / totalItems) * completedItems

  return (
    <ProgressBarStyled>
      <ProgressBarStroke
        width={itemWidth}
      />
    </ProgressBarStyled>
  )
}
