import { Translations } from '@jtb-don-fe/types'
import React, { FC } from 'react'

import { ButtonGroup } from '../../../../layout/button-group'
import { ButtonPrimary } from '../../../button/primary'
import { DragAndDropType } from '../../root'

interface WebcamButtonsProps {
  setType: (value: DragAndDropType) => void
  setValue: (value?: File) => void
  translations: Translations
}

export const WebcamButtons: FC<WebcamButtonsProps> = ({ setType, setValue, translations }) => {
  const onReset = () => {
    setValue(undefined)
    setType(DragAndDropType.Upload)
  }

  return (
    <ButtonGroup>
      <ButtonPrimary onClick={onReset} name='cancel'>
        {translations.button.webcam.cancel}
      </ButtonPrimary>
    </ButtonGroup>
  )
}
