import React, { FC } from 'react'
import { components, ControlProps } from 'react-select'

import { OptionType } from '../../select'

import { SelectControlStyled } from './select-control-styled'

export const SelectControl: FC<ControlProps<OptionType>> = (props) => (
  <SelectControlStyled
    toggleable={props.selectProps.toggleable}
    phoneSelect={props.selectProps.phoneSelect}
    activeOuter={props.selectProps.activeOuter}
    errors={props.selectProps.errors}
    showError={props.selectProps.showError}
    hasValue={props.hasValue}
    isFocused={props.isFocused}
    disabled={props.isDisabled}
  >
    <components.Control
      {...props}
    />
  </SelectControlStyled>
)
