import { Translations } from '@jtb-don-fe/types'
import { Field as CoreField, FieldError, withDefaultProps } from 'core-ui'
import React, { FC, RefObject, ClipboardEvent } from 'react'

import { Input } from '../../input'

import { FieldView } from './components'

export interface FieldProps<V> {
  name: string
  type?: string
  value?: V
  options?: { [key: string]: string }
  touched?: boolean
  dirty?: boolean
  updating?: boolean
  errors?: Array<FieldError>
  modifiers?: Array<(value: string) => string>
  validators?: Array<(value: string) => boolean>
  position?: number
  refs?: object
  inputRef?: RefObject<HTMLInputElement>
  required?: boolean
}

const defaultProps = {
  name: 'defaultName',
  RenderComponent: FieldView,
  components: {
    InputComponent: Input
  }
}

export interface FieldComponentProps<V = string | number | boolean | [] | object> {
  translations: Translations
  field: FieldProps<V>
  disabled?: boolean
  onChange?: (newValue: V) => void
  onFocus?: (newValue: V) => void
  onBlur?: (newValue: V) => void
  onPaste?: (newValue: V, event: ClipboardEvent) => void
}

interface DefaultPropsProps<T> {
  RenderComponent?: FC
  components?: {
    InputComponent?: T
    FieldLabelComponent?: FC
  }
  defaultProps?: typeof defaultProps
}

export const Field =
  <T, P>(): React.ComponentType<P & DefaultPropsProps<T>> => withDefaultProps(defaultProps, CoreField)
