import { AnimatePresence } from 'framer-motion'
import React, { FC, ReactNode, useState } from 'react'

import { Button } from '../components/button-styled'

import { ButtonWrapper } from './components/button-wrapper-styled'
import { ContentMotion } from './components/content-motion'
import { ContentWrapper } from './components/content-wrapper-styled'

interface ButtonTooltipProps {
  content: ReactNode
  name: string
  label?: string
}

export const ButtonTooltip: FC<ButtonTooltipProps> = ({ label, content, name }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <>
      <ButtonWrapper>
        {label}
        <Button
          onClick={() => setExpanded(!expanded)}
          data-test-id={`buttonTooltip-${name}`}
          type='button'
        />
      </ButtonWrapper>
      <AnimatePresence
        initial={false}
      >
        {expanded && (
          <ContentMotion>
            <ContentWrapper>
              {content}
            </ContentWrapper>
          </ContentMotion>
        )}
      </AnimatePresence>
    </>
  )
}
