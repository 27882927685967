import { isEmpty } from '@jtb-don-fe/utils'
import { useEffect, useState } from 'react'

declare global {
  interface Window {
    dataLayer: {
      push: (data: object) => void
    }
  }
}

const googleTagManagerId = 'tag-manager'

export const useGoogleTagManager = (tagManagerKey: string, disableTagManager: boolean) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (isEmpty(tagManagerKey) || disableTagManager) {
      return
    }

    const script = document.createElement('noscript')
    const iframe = document.createElement('iframe')
    const scriptSource = document.createElement('script')

    scriptSource.innerHTML = `
    <!-- Google Tag Manager -->
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
    var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;
    j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})
    (window,document,'script','dataLayer','${tagManagerKey}');
    <!-- End Google Tag Manager -->`

    script.id = googleTagManagerId
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${tagManagerKey}`
    iframe.height = '0'
    iframe.width = '0'
    iframe.setAttribute('style', 'display:none;visibility:hidden')

    document.head.appendChild(scriptSource)
    document.body.appendChild(script)

    const tagManager: HTMLElement | null = document.getElementById(googleTagManagerId)

    if (tagManager) {
      tagManager.appendChild(iframe)
      setLoaded(true)
    }
  }, [disableTagManager, tagManagerKey])

  return loaded
}
