import styled, { css } from 'styled-components'
import { space } from 'styled-system'

import { fontRegular, formBoxBackgroundColor, formBoxColor } from '../../../../../../theme'

interface FormBoxStyledProps {
  wideLayout: boolean
  fullHeight: boolean
}

export const FormBoxStyled = styled.main.attrs({
  padding: ['0 1.5rem 1.5rem 1.5rem', null, '3rem']
})<FormBoxStyledProps>`
  ${space};
  margin: 7rem auto;
  width: 100%;
  max-width: ${({ wideLayout }) => (wideLayout ? '94.5rem' : '75.1rem')};
  display: flex;
  flex-direction: column;
  color: ${formBoxColor};
  background-color: ${formBoxBackgroundColor};
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
  font-size: 1.5rem;
  line-height: 1.6em;
  ${fontRegular};
  ${({ fullHeight }) => fullHeight && css`
    height: calc(100vh - 240px);
    max-height: 600px;
    justify-content: center;
  `}
`
