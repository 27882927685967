import React, { FC } from 'react'

import op from '../../../assets/icons/paper/op.svg'

import { PaperDone } from './components/paper-done-styled'
import { ImgStyled } from './components/paper-img-styled'
import { PaperItem } from './components/paper-item'
import { LabelStyled } from './components/paper-label-styled'
import { PaperLeft } from './components/paper-left-styled'
import { WrapperStyled } from './components/wrapper-styled'

export interface IdentityPaperProps {
  onClick: () => void
  leftPartDone?: boolean
  translations: {
    [k: string]: string
  }
}

export const IdentityPaper: FC<IdentityPaperProps> = ({ onClick, translations, leftPartDone }) => (
  <WrapperStyled>
    <PaperLeft
      onClick={onClick}
    >
      <PaperItem
        active={leftPartDone}
      >
        <ImgStyled>
          <img
            src={op}
            alt={translations.identificationCard}
          />
        </ImgStyled>
        <LabelStyled>
          {translations.identificationCard}
        </LabelStyled>
        {leftPartDone && (
          <PaperDone>
            {translations.done}
          </PaperDone>
        )}
      </PaperItem>
    </PaperLeft>
  </WrapperStyled>
)
