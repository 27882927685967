import styled from 'styled-components'

import { fontMedium, identityPaperDoneColor } from '../../../theme'

export const PaperDone = styled.div`
  margin: -0.5rem 0 0;
  color: ${identityPaperDoneColor};
  font-size: 1.4rem;
  ${fontMedium};
`
