import styled from 'styled-components'

import { Flex } from '../../../generic/layout/flex'

interface LeftBox {
  showSpecificError: boolean
}

export const LeftBox = styled(Flex).attrs<LeftBox>(({ showSpecificError }) => ({
  flex: [1, null, '0 0 15rem'],
  padding: ['0 0 1.5rem', null, '0 3rem 0'],
  alignSelf: showSpecificError ? 'center' : 'flex-end',
  justifyContent: ['center', null, 'flex-end']
}))<LeftBox>`
  width: 100%;
  text-align: right;
`
