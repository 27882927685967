import styled from 'styled-components'

import { textColor } from '../../../../../theme'

export const Button = styled.button`
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  box-shadow: none;
  border: 0 none;
  outline: none;
  background-color: transparent;
  line-height: 2rem;
  margin: -0.5rem 0.5rem;

  &:after {
    content: 'j';
    font-family: 'jat';
    font-weight: normal;
    text-transform: none;
    font-size: 1.6rem;
    color: ${textColor};
  }
`
