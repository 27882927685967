import React, { useState, useEffect, memo, RefObject } from 'react'

interface WebcamProps {
  width?: number
  height?: number
  videoRef: RefObject<HTMLVideoElement>
  audio?: boolean
  status?: 'on' | 'off'
  onStream?: (stream: MediaStream | null) => void
}

const createStream = async (
  status: WebcamProps['status'],
  width: WebcamProps['width'],
  audio: WebcamProps['audio'],
  onStream: WebcamProps['onStream'],
  setStream: WebcamProps['onStream']
) => {
  const stream =
    status === 'on'
      ? await navigator.mediaDevices.getUserMedia({
          video: { width },
          audio
        })
      : null

  onStream && onStream(stream)
  setStream && setStream(stream)
}

export const Webcam = memo(
  ({ width = 640, height = 400, audio = false, status = 'off', videoRef, onStream }: WebcamProps) => {
    const [stream, setStream] = useState<MediaStream | null>(null)

    useEffect(() => {
      createStream(status, width, audio, onStream, setStream)
    }, [status, width, audio, onStream, setStream])

    useEffect(() => {
      const video = videoRef.current

      if (video) {
        if ('srcObject' in video) {
          video.srcObject = stream
        } else {
          // @ts-ignore - `video.src` fallback
          video.src = URL.createObjectURL(stream)
        }
      }
    }, [videoRef, stream])

    return <video ref={videoRef} width={width} height={height} autoPlay={status === 'on'} />
  }
)
