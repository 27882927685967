import React, { RefObject, useEffect, useRef, memo } from 'react'

interface WebcamCaptureProps {
  x: number
  y: number
  width: number
  height: number
  videoRef: RefObject<HTMLVideoElement>
  capture: (image: Blob) => void
  shouldCapture: boolean
  shouldRemoveVideoOnCapture?: boolean
}

const drawImage = async(
  x: number,
  y: number,
  width: number,
  height: number,
  onDraw: (image: Blob) => void,
  video: HTMLVideoElement,
  canvas?: HTMLCanvasElement
) => {
  const ctx = canvas && canvas.getContext('2d')
  ctx && ctx.drawImage(video, x, y, width, height)
  const blob = (await new Promise((resolve) => {
    canvas && canvas.toBlob((blob: Blob | null) => {
      blob && resolve(blob)
    })
  })) as Blob

  onDraw(blob)
}

const removeVideo = (video: HTMLVideoElement) => {
  const srcObject = video.srcObject as MediaStream
  srcObject.getVideoTracks().forEach((track: MediaStreamTrack) => track.stop())
  video.remove()
}

export const WebcamCapture = memo(
  ({ x, y, width, height, videoRef, capture, shouldRemoveVideoOnCapture, shouldCapture }: WebcamCaptureProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null)
    useEffect(() => {
      if (!shouldCapture) {
        return
      }
      const video = videoRef && videoRef.current
      const canvas = canvasRef.current || undefined
      video && drawImage(x, y, width, height, capture, video, canvas)

      if (shouldRemoveVideoOnCapture) {
        video && removeVideo(video)
      }
    }, [capture, height, shouldCapture, shouldRemoveVideoOnCapture, videoRef, width, x, y])

    return (
      <canvas
        ref={canvasRef}
        width={width}
        height={height}
      />
    )
  }
)
