import { CSSProperties } from 'react'
import styled from 'styled-components'

import { textColor } from '../../../../theme'
import { TextCss, TextType } from '../../root/text'

type TitleStyledProps = TextType & Pick<CSSProperties, 'textAlign' | 'color'>

export const TitleStyled = styled.h1.attrs<TitleStyledProps>({
  margin: ['3rem 0 1.5rem', null, '3.6rem 0 1.5rem'],
  'data-test-id': 'title'
})<TitleStyledProps>`
  ${TextCss};
  color: ${({ color }) => color || textColor};
  font-size: 2.4rem;
  font-weight: 500;
  text-align: ${({ textAlign }) => textAlign || 'center'};
  line-height: 3.36rem;
`
