import styled from 'styled-components'

import { buttonDownloadTextColor, buttonDownloadTextActiveColor } from '../../../../../theme'

interface IconWrapperProps {
  hover?: boolean
}

export const IconWrapper = styled.div<IconWrapperProps>`
  display: flex;
  padding: 2rem;
  flex: 0 0 9rem;
  background-color: ${({ hover }) => (hover ? buttonDownloadTextActiveColor : buttonDownloadTextColor)};
  transition: background-color 150ms linear;
`
