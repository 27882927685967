import React from 'react'
import { Translations } from '@jtb-don-fe/types'

import { Paragraph, Text } from '../../text'
import { ButtonPrimary, ButtonSecondary } from '../../form'
import { Grid } from '../../layout'
import { theme } from '../../../theme'

import { BannerWrapper } from './cookies-banner-styled'

interface CookiesBannerProps {
  onCookiesSettings(): void
  onRefuseCookies(): void
  onAllowCookies(): void
  translations: Translations
}

export const CookiesBanner = ({
  translations,
  onCookiesSettings,
  onRefuseCookies,
  onAllowCookies
}: CookiesBannerProps) => {
  if (!translations) {
    return null
  }

  const isWelcome = window?.location?.pathname === '/welcome' || window?.location?.pathname === '/welcome/'

  return (
    <BannerWrapper isWelcome={isWelcome}>
      <div>
        <Text fontSize='26px' my={2} as='h4' fontWeight='500'>
          {translations.title}
        </Text>
        <Paragraph mb={1} mt={2}>
          {translations.description}
        </Paragraph>
        <Text>
          <a style={{ color: theme.color.primary }} href={`${window.location.origin}/web/cookies-info`}>
            {translations.link}
          </a>
        </Text>
      </div>
      <Grid gridGap='1.5rem' gridTemplateColumns={['100%', '4fr 1fr 1fr 1fr']}>
        <div />
        <ButtonSecondary width='auto' name='cookies-settings' onClick={onCookiesSettings}>
          {translations.button.cookiesSettings}
        </ButtonSecondary>
        <ButtonPrimary width='auto' name='refuseCookies' onClick={onRefuseCookies}>
          {translations.button.refuseCookies}
        </ButtonPrimary>
        <ButtonPrimary width='auto' name='allowCookies' onClick={onAllowCookies}>
          {translations.button.allowCookies}
        </ButtonPrimary>
      </Grid>
    </BannerWrapper>
  )
}
