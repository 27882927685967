import { notEmpty } from '@jtb-don-fe/utils'
import React, { MutableRefObject } from 'react'
import { IMaskMixin } from 'react-imask'
import styled from 'styled-components'

import { inputBackgroundColor } from '../../../../../theme'
import { inputBaseStyledCss } from '../../base/input-base-styled'

interface BackgroundColor {
  defaultValue?: string
  active?: boolean
}

const backgroundColor = ({ defaultValue, active }: BackgroundColor) =>
  (notEmpty(defaultValue) || active ? 'transparent' : inputBackgroundColor)

export const InputPhoneStyled = styled.input<BackgroundColor>`
  ${inputBaseStyledCss}
  padding-left: 7rem;
  background-color: ${backgroundColor};
`

interface MaskedStyledInput {
  defaultValue: string
  inputRef: MutableRefObject<HTMLInputElement>
}

export const MaskedStyledInput = IMaskMixin((mixinProps: MaskedStyledInput) => {
  const { inputRef, ...props } = mixinProps

  return (
    <InputPhoneStyled
      {...props}
      ref={inputRef}
    />
  )
})
