import { notEmpty, pushFormAction } from '@jtb-don-fe/utils'
import { FieldError } from 'core-ui'
import React, { FC, FocusEvent, ReactNode, useEffect } from 'react'

import { Label, Input, CheckMark, LabelProps } from './checkbox-styled'

export interface CheckboxProps extends Omit<LabelProps, 'onClick'> {
  name: string
  value: boolean
  onChange: (value: boolean) => void
  onFocus?: (event: FocusEvent) => void
  onBlur?: (event: FocusEvent) => void
  labelComponent: ReactNode
  errors?: Array<FieldError>
  dirty?: boolean
  updating?: boolean
}

export const CheckboxView: FC<CheckboxProps> = (props) => {
  const { name, value, labelComponent, onChange, onFocus, onBlur, errors, dirty, updating } = props

  const onChangeHandler = (): void => {
    onChange(!value)
  }
  const showError = !!(notEmpty(errors) && (updating || dirty))

  useEffect(() => {
    pushFormAction(value ? 'check' : 'uncheck', name, dirty ? errors?.length : 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <Label
      htmlFor={name}
      showError={showError}
      data-error={showError}
      data-test-id={`checkbox-${name}`}
    >
      {labelComponent}
      <Input
        type='checkbox'
        name={name}
        id={name}
        onChange={onChangeHandler}
        onFocus={onFocus}
        onBlur={onBlur}
        checked={!!value}
      />
      <CheckMark />
    </Label>
  )
}
