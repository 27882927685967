import { FC } from 'react'
import styled from 'styled-components'

import { Flex } from '../../../layout'
import { theme } from '../../../../theme'
import { InputToggleProps } from '../types'

type ToggleWrapper = Pick<InputToggleProps, 'disabled' | 'checked' | 'labelBefore'>

const cursorDisabled = ({ disabled }: ToggleWrapper) => (disabled ? 'not-allowed' : 'pointer')

const labelColor = ({ disabled }: ToggleWrapper) => (disabled ? '#D7DBD9' : theme.color.text)

const toggleBorder = ({ checked }: ToggleWrapper) => (checked ? theme.color.primary : '#D7DBD9')

const toggleBackground = ({ checked }: ToggleWrapper) => (checked ? theme.color.primary : '#D7DBD9')

const leftTogglePosition = ({ checked, labelBefore }: ToggleWrapper) => {
  if (labelBefore) {
    return 'auto'
  }

  return checked ? '2.4rem' : '0.2rem'
}

const rightTogglePosition = ({ checked, labelBefore }: ToggleWrapper) => {
  if (!labelBefore) {
    return 'auto'
  }

  return checked ? '0.2rem' : '2.4rem'
}

export const ToggleWrapper: FC<ToggleWrapper> = styled(Flex)<ToggleWrapper>`
  width: fit-content;
  max-width: 100%;

  input[type='checkbox'] {
    position: absolute;
    z-index: 0;
    opacity: 0;
    margin: 0;
    margin-inline-start: ${({ labelBefore }) => (labelBefore ? 0 : '0.2rem')};
    margin-inline-end: ${({ labelBefore }) => (labelBefore ? '0.2rem' : 0)};
    transition: box-shadow 0.25s ease-in-out;
  }

  input[type='checkbox']:hover + label::before,
  input[type='checkbox']:focus + label::before {
    box-shadow: 0 0 0 0.4rem #e7f6f5;
    transition: box-shadow 0.25s ease-in-out;
    outline: transparent;
  }

  label {
    position: relative;
    z-index: 10;
    display: inline-block;
    flex: 1;
    cursor: ${cursorDisabled};
    color: ${labelColor};
    padding-inline-start: ${({ labelBefore }) => (labelBefore ? 0 : '5.6rem')};
    padding-inline-end: ${({ labelBefore }) => (labelBefore ? '5.6rem' : 0)};
    transition: color 0.25s ease-in-out;
  }

  label::before,
  label::after {
    position: absolute;
    content: '';
    display: inline-block;
  }

  label::before {
    border-radius: 1.6rem;
    width: 4.2rem;
    height: 2rem;
    left: ${({ labelBefore }) => (labelBefore ? 'auto' : 0)};
    right: ${({ labelBefore }) => (labelBefore ? 0 : 'auto')};
    top: 0;
    border-width: 0.2rem;
    border-style: solid;
    border-color: ${toggleBorder};
    background: ${toggleBackground};
    transition: all 0.25s ease-in-out;
    opacity: ${({ disabled }) => (disabled ? '0.4' : 'initial')};
  }

  label::after {
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    top: 0.2rem;
    left: ${leftTogglePosition};
    right: ${rightTogglePosition};
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    background-repeat: no-repeat;
    background-position: center;
    background-color: white;
    border-radius: 0.8rem;
    transition: all 0.25s ease-in-out;
  }
`
