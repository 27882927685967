import { MouseEvent } from 'react'
import styled from 'styled-components'

import { fontMedium } from '../../../../theme'

export interface ButtonProps {
  name: string
  width?: number | string
  dimmed?: boolean
  disabled?: boolean
  onClick?: (event: MouseEvent) => void
  hidden?: boolean
  type?: string
  formValid?: boolean
}

export const ButtonStyled = styled.button<ButtonProps>`
  width: ${({ width = 100 }) => (width === 'auto' ? 'auto' : `${width}%`)};
  height: 6rem;
  border: 1px solid transparent;
  font-size: 1.4rem;
  border-radius: 0;
  line-height: 1.3em;
  padding: 1.1rem 1.5rem;
  transition: background-color 150ms linear, color 150ms linear, opacity 150ms linear;
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  opacity: ${({ dimmed, disabled }) => (dimmed || disabled ? '0.5' : 1)};
  font-family: 'Geometria';
  ${fontMedium}
`
