import React from 'react'
import { Translations } from '@jtb-don-fe/types'

import { InputToggle } from '../../form'
import { Flex } from '../../layout'
import { Text, Paragraph } from '../../text'

import { CookiesTable } from './cookies-table'

interface AnalyticCookiesTabProps {
  withAnalytics: boolean
  setWithAnalytics(withAnalytics: boolean): void
  translations: Translations
}

export const AnalyticCookiesTab = ({ withAnalytics, setWithAnalytics, translations }: AnalyticCookiesTabProps) => (
  <div style={{ padding: '1rem', width: '80%' }}>
    <Flex alignItems='center' justifyContent='space-between'>
      <Text as='h3' fontSize='1.7em'>
        {translations.title}
      </Text>
      <Flex pb='3rem'>
        <InputToggle
          name='analytic-cookies'
          checked={withAnalytics}
          onChange={(event) => setWithAnalytics(event.target.checked)}
        />
      </Flex>
    </Flex>
    <Paragraph mt='1rem'>{translations.description}</Paragraph>
    <CookiesTable translations={translations.table} />
  </div>
)
