import { css } from 'styled-components'

import { fontMedium, linkColor, textColor } from '../../../theme'

export const sanitizedContentClass = css`
  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .strong {
    font-weight: 700;
    color: ${textColor};
  }

  .link {
    display: inline-block;
    color: ${linkColor};
    text-decoration: underline;
    transition: color 150ms linear;
    ${fontMedium};
  }
`
