export { addReducer, configureSaga, createStore } from 'core-ingredients';
import { mergeDeepRight, map } from 'ramda';

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

var createTheme = function createTheme(defaultTheme) {
  if (process.env.theme) {
    var theme = process.env.theme;

    if (theme.image) {
      var requireAssets = require.context(process.env.themeAssets, true);

      return mergeDeepRight(defaultTheme, _objectSpread2({}, theme, {
        image: map(requireAssets, theme.image)
      }));
    }

    return mergeDeepRight(defaultTheme, theme);
  }

  return defaultTheme || null;
};

var fs = require('fs');

var path = require('path');

var resolveProxies = function resolveProxies(defaults) {
  var variables = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return _objectSpread2({}, defaults, {}, variables);
};

var resolvePaths = function resolvePaths(defaults, variables) {
  var options = _objectSpread2({}, defaults, {}, variables);

  var context = path.resolve(process.cwd(), options.context);
  var source = path.resolve(process.cwd(), options.source);
  var output = path.resolve(process.cwd(), options.output);
  return {
    context: context,
    source: source,
    output: output
  };
};

var resolveTheme = function resolveTheme(config) {
  if (config) {
    var themePath = path.resolve(process.cwd(), config);
    var content = fs.readFileSync(themePath, 'utf8');
    var theme = JSON.parse(content);
    var themeAssets = path.dirname(themePath);
    return {
      theme: theme,
      themeAssets: themeAssets
    };
  }

  return {
    theme: null,
    themeAssets: null
  };
};

var resolveWebpackOptions = function resolveWebpackOptions(defaults, variables) {
  var _defaults$variables = _objectSpread2({}, defaults, {}, variables),
      host = _defaults$variables.host,
      port = _defaults$variables.port,
      root = _defaults$variables.root;

  var proxies = resolveProxies(defaults.proxy, variables.proxy);
  var paths = resolvePaths(defaults.path, variables);
  var theme = resolveTheme(variables.theme);
  return {
    host: host,
    port: port,
    root: root,
    paths: paths,
    theme: theme,
    proxies: proxies
  };
};
var baseWebpackConfig = function baseWebpackConfig(options) {
  var root = options.root,
      paths = options.paths,
      mode = options.mode;
  var production = mode === 'production';
  return {
    entry: {
      app: ['core-js/stable', paths.source]
    },
    context: paths.context,
    output: {
      filename: production ? '[name].[hash].js' : '[name].bundle.js',
      chunkFilename: production ? '[name].[contenthash].js' : '[name].bundle.js',
      path: paths.output,
      publicPath: root
    },
    mode: mode,
    bail: production,
    devtool: production ? 'source-map' : 'eval-source-map',
    optimization: {
      splitChunks: {
        chunks: 'all'
      }
    },
    node: {
      fs: 'empty'
    },
    stats: 'minimal'
  };
};

var babelLoader = function babelLoader() {
  return {
    test: /\.(js|jsx|ts|tsx)$/,
    exclude: /node_modules/,
    use: {
      loader: 'babel-loader',
      options: {
        presets: [['@babel/preset-env', {
          useBuiltIns: 'entry',
          corejs: 3
        }]]
      }
    }
  };
};

var styleLoader = function styleLoader() {
  return {
    test: /\.css$/,
    use: ['style-loader', 'css-loader']
  };
};

var assetLoader = function assetLoader() {
  return {
    test: /\.(png|jpe?g|gif|svg|eot|ico)$/,
    use: [{
      loader: 'file-loader',
      options: {
        outputPath: 'images'
      }
    }]
  };
};

var fontsLoader = function fontsLoader() {
  return {
    test: /\.(woff|woff2|ttf)$/,
    use: [{
      loader: 'file-loader',
      options: {
        outputPath: 'fonts'
      }
    }]
  };
};

var webpackLoaders = {
  babel: babelLoader,
  style: styleLoader,
  asset: assetLoader,
  fonts: fontsLoader
};
var devServerConfig = function devServerConfig(proxyConfig, options) {
  var mode = options.mode,
      host = options.host,
      port = options.port,
      root = options.root,
      proxies = options.proxies;
  var production = mode === 'production';

  if (production) {
    return {};
  }

  var proxy = proxyConfig(proxies);
  return {
    devServer: {
      host: host,
      port: port,
      compress: true,
      historyApiFallback: {
        index: root
      },
      stats: 'minimal',
      proxy: proxy,
      hot: !production,
      allowedHosts: ['test.kekel.kek']
    }
  };
};
var webpackPluginConfigs = function webpackPluginConfigs(options) {
  var paths = options.paths,
      mode = options.mode;
  var production = mode === 'production';
  return {
    filename: 'index.html',
    template: path.resolve(paths.source, 'index.html'),
    inject: 'body',
    chunksSortMode: 'none',
    scriptLoading: 'defer',
    minify: {
      removeComments: production,
      collapseWhitespace: production,
      minifyJS: production,
      minifyCSS: production,
      html5: true
    }
  };
};

export { baseWebpackConfig, createTheme, devServerConfig, resolveWebpackOptions, webpackLoaders, webpackPluginConfigs };
