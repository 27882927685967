import { isEmpty } from '@jtb-don-fe/utils'
import React, { FC } from 'react'

import { theme } from '../../../theme'

import { HeaderContainer } from './components/header-container'
import { HeaderListItem } from './components/header-list-item-styled'
import { HeaderList } from './components/header-list-styled'
import { HeaderLogo } from './components/header-logo'
import { HeaderStyled } from './components/header-styled'
import { ProgressBar } from './components/progress-bar/progress-bar'

export type Progress = {
  items: Array<string>
  activeIndex: number
  visible: boolean
}

interface HeaderProps {
  progress: Progress
}

export const Header: FC<HeaderProps> = ({ progress }) => (
  <>
    <HeaderStyled>
      <HeaderContainer
        centered={!progress.visible || progress.items.length === 0}
      >
        <HeaderLogo>
          <a
            href='https://www.jtbank.cz/'
            title='J&amp;T Banka'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              src={theme.image.logo}
              alt='J&amp;T Banka'
              width={118}
              height={17}
            />
          </a>
        </HeaderLogo>

        {progress.visible && (
          <HeaderList>
            {progress.items.map((item, index) => (
              <HeaderListItem
                key={item}
                active={progress.activeIndex === index}
                passed={progress.activeIndex > index}
                hidden={isEmpty(item)}
              >
                {item}
              </HeaderListItem>
            ))}
          </HeaderList>
        )}
      </HeaderContainer>
    </HeaderStyled>

    {progress.visible && (
      <ProgressBar
        progress={progress}
      />
    )}
  </>
)
