import React, { FC, ReactNode } from 'react'

import { FormBoxStyled } from './components/form-box-styled'

export interface FormBoxProps {
  children: ReactNode
  wideLayout: boolean
  fullHeight: boolean
}

export const FormBox: FC<FormBoxProps> = ({ children, ...props }) => (
  <FormBoxStyled
    {...props}
  >
    {children}
  </FormBoxStyled>
)
