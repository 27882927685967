import { FC, MouseEvent } from 'react'
import styled, { css } from 'styled-components'

import {
  buttonPrimaryBackgroundColor,
  buttonPrimaryBackgroundActiveColor,
  buttonPrimaryTextColor
} from '../../../../../theme'
import { ButtonStyled } from '../../components/button-styled'

interface ButtonPrimaryStyledProps {
  type?: string
  onClick?: (event: MouseEvent) => void
  formValid?: boolean
}

export const ButtonPrimaryStyled: FC<ButtonPrimaryStyledProps> = styled(ButtonStyled)`
  border: 1px solid transparent;
  color: ${buttonPrimaryTextColor};
  background-color: ${buttonPrimaryBackgroundColor};
  transition: background-color 150ms linear, color 150ms linear, opacity 150ms linear;

  ${({ dimmed, disabled }) => (!dimmed && !disabled) && css`
    &:hover {
      background-color: ${buttonPrimaryBackgroundActiveColor};
      border: 1px solid ${buttonPrimaryBackgroundActiveColor};
      color: ${buttonPrimaryTextColor};
    }
  `}
`
