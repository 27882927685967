import { isEmpty, notEmpty } from '@jtb-don-fe/utils'
import { css } from 'styled-components'

import {
  fontMedium, inputBackgroundColor, inputBorderActiveColor, inputBorderColor, inputBorderErrorColor,
  inputBorderWarnColor, inputPlaceholderColor, inputTextColor
} from '../../../../theme'

import { InputBaseProps } from './input-base'

const borderStyles = ({ warn, value, dirty }: InputBaseStyledProps) => {
  const showWarn = warn && isEmpty(value) && !dirty

  return css`
      border-color: ${showWarn ? inputBorderWarnColor : inputBorderColor};
      border-width: ${showWarn ? '2px' : '1px'};
      border-style: solid;

      &:focus {
        border-color: ${inputBorderActiveColor};
      }
    `
}

const borderErrorStyles = () => css`
  border-color: ${inputBorderErrorColor};
  border-width: 2px;
  border-style: solid;
`

export interface InputBaseStyledProps extends Pick<
  InputBaseProps,
  'value' | 'disabled' | 'errors' | 'showError' | 'dirty' | 'warn'
  > {
  active?: boolean
  type?: string
}

export const inputBaseStyledCss = css<InputBaseStyledProps>`
  height: 6rem;
  width: 100%;
  background-color: ${({ value, active }) => (notEmpty(value) || active ? 'transparent' : inputBackgroundColor)};
  color: ${({ disabled }) => (disabled ? inputTextColor : inputTextColor)};
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.3em;
  border-radius: 0;
  padding: 1.6rem;
  opacity: ${({ disabled }) => (disabled ? '0.4' : 1)};
  transition: background-color 150ms linear, border 150ms linear;
  ${({ errors, showError, ...rest }) => (showError && notEmpty(errors) ? borderErrorStyles() : borderStyles(rest))};
  font-family: Geometria;
  ${fontMedium};
  box-shadow: none!important;
  background-clip: padding-box;

  ${({ type }) => type === 'date' && css`
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  `}

  &::-webkit-input-placeholder {
    color: ${inputPlaceholderColor};
    font-weight: 400;
  }
`
