import React from 'react'
import styled from 'styled-components'
import { Translations } from '@jtb-don-fe/types'

import { Flex } from '../../layout'

const TableStyled = styled.table`
  white-space: nowrap;
  width: auto;
  border-spacing: 0;

  tr:nth-child(2n) > td {
    background-color: #f5f5f5;
  }

  td {
    padding: 0.8rem;
  }

  th {
    text-align: left;
    padding: 0.8rem;
  }
`

type Row = { cookieName: string; domain: string; validTo: string; description: string }

interface CookiesTableProps {
  translations: Translations
}

export const CookiesTable = ({ translations }: CookiesTableProps) => (
  <Flex py='1.5rem' width='100%' overflowX='auto'>
    <TableStyled>
      <tr>
        <th>{translations.header.cookieName}</th>
        <th>{translations.header.domain}</th>
        <th>{translations.header.validTo}</th>
        <th>{translations.header.description}</th>
      </tr>
      {translations.rows?.map(({ cookieName, domain, validTo, description }: Row) => (
        <tr>
          <td>{cookieName}</td>
          <td>
            {domain === '{domainName}'
              ? window?.location?.origin.replace('http://', '').replace('https://', '')
              : domain}
          </td>
          <td>{validTo}</td>
          <td>{description}</td>
        </tr>
      ))}
    </TableStyled>
  </Flex>
)
