import { notEmpty, pushFormAction } from '@jtb-don-fe/utils'
import cs from 'date-fns/locale/cs'
import { DateTime } from 'luxon'
import React, { FC, FocusEvent, useCallback, useEffect, useRef, useState } from 'react'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { InputBaseProps } from '../../base/input-base'
import { Error } from '../../base/input-base-error-styled'

import { InputDateCustom } from './input-date-custom'
import { InputDateWrapper } from './input-date-wrapper'

registerLocale('cs', cs)
setDefaultLocale('cs')

export type InputDateViewProps = InputBaseProps & {
  openCalendar: string
}

export const InputDateView: FC<InputDateViewProps> = ({
  onChange,
  placeholder,
  value,
  errors = [],
  onFocus,
  onBlur,
  warn,
  ...restProps
}) => {
  const [active, setActive] = useState(false)
  const zone = useRef('utc')

  const showError = notEmpty(errors) && (restProps.updating || restProps.dirty)
  const error = showError && notEmpty(errors) && !active

  const getPlaceholder = () => (active ? '' : placeholder)

  const onFocusHandler = (event: FocusEvent) => {
    onFocus && onFocus(event)
    pushFormAction('form field entry', restProps.name, restProps.dirty ? errors?.length : 0)
  }

  const onBlurHandler = (event: FocusEvent) => {
    onBlur && onBlur(event)
    pushFormAction('form field exit', restProps.name, errors?.length)
  }

  useEffect(() => {
    if (restProps.openCalendar !== restProps.name) {
      setActive(false)
    }
  }, [restProps.openCalendar, restProps.name])

  const fromDateInputFormat = (value: string) => {
    const dateTime = DateTime.fromFormat(value, 'dd.MM.yyyy')

    if (dateTime.isValid) {
      return DateTime.fromObject({
        day: dateTime.day,
        month: dateTime.month,
        year: dateTime.year,
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
        zone: zone.current
      }).toFormat('yyyy-MM-dd\'T\'HH:mm:ss.SSSZZZ')
    }

    return value
  }

  const onChangeHandler = useCallback((date: string) => {
    const value = fromDateInputFormat(date)

    onChange && onChange(value)
  }, [onChange])

  const selectedFromISO = value && DateTime.fromISO(value)

  const raw = value && value.split('.')
  let selectedFromRaw

  if (raw && raw.length > 0) {
    const [day, month, year] = raw

    selectedFromRaw = new Date(`${month}.${day}.${year}`)
  }

  let selected

  if (selectedFromISO && selectedFromISO.isValid) {
    selected = selectedFromISO.toJSDate()
  }

  // eslint-disable-next-line no-restricted-globals
  if (selectedFromRaw && !isNaN(selectedFromRaw.getTime())) {
    selected = selectedFromRaw
  }

  return (
    <InputDateWrapper>
      <DatePicker
        {...restProps}
        open={active}
        onInputClick={() => setActive(true)}
        dateFormat='dd.MM.yyyy'
        selected={selected}
        onKeyDown={() => setActive(false)}
        onChange={(value) => {
          if (active && value && value instanceof Date) {
            const dateTime = DateTime.fromJSDate(value)
            onChangeHandler(dateTime.toFormat('dd.MM.yyyy'))
            setActive(false)
          }
        }}
        onChangeRaw={(event) => {
          if (event.target.value !== undefined) {
            onChangeHandler(event.target.value)
          }
        }}
        onBlur={() => setActive(false)}
        customInput={(
          <InputDateCustom
            active={active}
            errors={errors}
            showError={showError}
            placeholder={getPlaceholder()}
            onFocusHandler={onFocusHandler}
            onBlurHandler={onBlurHandler}
            warn={warn}
          />
        )}
        showYearDropdown
      />
      {error && (
        <Error
          data-test-id={`fieldError-${restProps.name}`}
          inputValue={value}
        >
          {errors[0].message}
        </Error>
      )}
    </InputDateWrapper>
  )
}
