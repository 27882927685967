import styled from 'styled-components'

import { progressBarStrokeBackgroundColor } from '../../../../../../theme'

interface ProgressBarStrokeProps {
  width: number
}

export const ProgressBarStroke = styled.div<ProgressBarStrokeProps>`
  height: 0.4rem;
  position: absolute;
  left: 0;
  top: 0;
  background-color: ${progressBarStrokeBackgroundColor};
  display: block;
  width: ${({ width }) => width || 0}%;
  transition: width 150ms linear;
`
