import DOMPurify from 'dompurify'
import { decode } from 'he'
import React, { ElementType, FC, useEffect, useMemo } from 'react'

import { Wrapper } from './components/wrapper-styled'

interface SanitizeHtmlProps {
  children?: string
  WrapperComponent?: ElementType
}

export const SanitizeHtml: FC<SanitizeHtmlProps> = ({ children, WrapperComponent }) => {
  const Component = WrapperComponent || Wrapper

  useEffect(() => {
    const allowedURISchemes = ['http', 'https', 'mailto', 'tel']

    const regex = RegExp(`^(${allowedURISchemes.join('|')}):`, 'gim')

    DOMPurify.addHook('afterSanitizeAttributes', (node) => {
      const anchor = document.createElement('a')

      if (node.hasAttribute('href')) {
        anchor.href = node.getAttribute('href') || ''

        if (anchor.protocol && !anchor.protocol.match(regex)) {
          node.removeAttribute('href')
        }
      }
    })
  }, [])

  const html = useMemo(
    () => ({
      __html: DOMPurify.sanitize(decode(children || ''), {
        ALLOWED_TAGS: ['a', 'strong', 'br', 'ul', 'ol', 'li', 'span'],
        ALLOWED_ATTR: ['href', 'target', 'class', 'src', 'style']
      })
    }),
    [children]
  )

  return children ? (
    <Component
      dangerouslySetInnerHTML={html}
    />
  ) : null
}
