import styled from 'styled-components'

import { progressBarBackgroundColor } from '../../../../../../theme'

export const ProgressBarStyled = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.4rem;
  display: flex;
  background-color: ${progressBarBackgroundColor};
  top: 0;
  z-index: 105;
`
