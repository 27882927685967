import styled from 'styled-components'

import {
  radioButtonBackgroundColor, radioButtonTextColor, radioButtonTextNothingCheckedColor,
  fontMedium, radioButtonCircleBorderColor
} from '../../../../../../theme'

export interface LabelProps {
  anySiblingChecked: boolean
}

export const Label = styled.label<LabelProps>`
  display: flex;
  padding-left: 40px;
  padding-right: 15px;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  min-height: 6.0rem;
  width: 100%;
  border: 1px solid transparent;
  color: ${(props) => (props.anySiblingChecked ? radioButtonTextNothingCheckedColor : radioButtonTextColor)};
  background-color: ${radioButtonBackgroundColor};
  font-size: 1.5rem;
  border-radius: 0;
  line-height: 1.3rem;
  transition: background-color 150ms linear, border 150ms linear, color 150ms linear;
  box-shadow: none;
  margin: 0;
  ${fontMedium}

  &:before,
  &:after {
    position: absolute;
    top: 50%;
    font-family: jat;
    font-weight: 400;
    content: " ";
    color: ${radioButtonCircleBorderColor};
    background-color: transparent;
    vertical-align: middle;
    text-align: center;
  }

  &:before {
    left: 1.5rem;
    text-transform: none;
    border: 2px solid ${radioButtonCircleBorderColor};
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    line-height: 1rem;
    margin-top: -0.7rem;
    font-size: 1rem;
  }

  &:after {
    left: 1.8rem;
    text-transform: none;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    line-height: 0.8rem;
    margin-top: -0.4rem;
    font-size: 0.8rem;
  }
`
