import DOMPurify from 'dompurify'
import { decode } from 'he'
import React, { FC, useEffect } from 'react'

import { ListItemStyled, ListItemStyledProps } from './components/list-item-styled'

export const ListItem: FC<ListItemStyledProps> = ({ children, display }) => {
  useEffect(() => {
    const allowedURISchemes = ['http', 'https', 'mailto', 'tel']

    const regex = RegExp(`^(${allowedURISchemes.join('|')}):`, 'gim')

    DOMPurify.addHook('afterSanitizeAttributes', (node) => {
      const anchor = document.createElement('a')

      if (node.hasAttribute('href')) {
        anchor.href = node.getAttribute('href') || ''

        if (anchor.protocol && !anchor.protocol.match(regex)) {
          node.removeAttribute('href')
        }
      }
    })
  }, [])

  return (
    (typeof children === 'string'
      ? (
        <ListItemStyled
          display={display}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(decode(children), {
              ALLOWED_TAGS: ['a', 'strong', 'br', 'ul', 'ol', 'li', 'span'],
              ALLOWED_ATTR: ['href', 'target', 'class', 'src', 'style']
            })
          }}
        />
      )
      : (
        <ListItemStyled
          display={display}
        >
          {children}
        </ListItemStyled>
      )
    )
  )
}
