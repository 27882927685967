import React, { FC } from 'react'

import { FormStyled } from './components/form-styled'

export const Form: FC = ({ children, ...props }) => (
  <FormStyled
    {...props}
  >
    {children}
  </FormStyled>
)
