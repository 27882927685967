import { ThemeProps } from 'styled-components'

import icAdult from '../../assets/icons/18.svg'
import icCzSkNationality from '../../assets/icons/czsk.svg'
import icDone from '../../assets/icons/done.svg'
import icAccountStatement from '../../assets/icons/ic-account-statement.svg'
import icIdPassport from '../../assets/icons/ic-id-passport.svg'
import icPhoneMail from '../../assets/icons/ic-phone-mail.svg'
import icPdf from '../../assets/icons/pdf.svg'

import { Theme } from './index'

export const iconTheme = {
  accountStatement: icAccountStatement,
  done: icDone,
  idPassport: icIdPassport,
  phoneMail: icPhoneMail,
  pdf: icPdf,
  adult: icAdult,
  czSkNationality: icCzSkNationality
}

type IconTheme = typeof iconTheme

type Icon = keyof IconTheme

const icon = (type: Icon) => (props: ThemeProps<Theme>) => props.theme.icon[type]

export const accountStatement = icon('accountStatement')
export const done = icon('done')
export const idPassport = icon('idPassport')
export const phoneMail = icon('phoneMail')
export const pdf = icon('pdf')
export const adult = icon('adult')
export const czSkNationality = icon('czSkNationality')
