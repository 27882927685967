import React, { FC } from 'react'

import { PaperItemStyled, PaperItemStyledProps } from './paper-item-styled'

export const PaperItem: FC<PaperItemStyledProps> = ({ children, active }) => (
  <PaperItemStyled
    active={active}
  >
    {children}
  </PaperItemStyled>
)
