import React, { FC } from 'react'
import { isMobile } from 'react-device-detect'

import { FieldComponentProps } from '../../root/field'
import { FieldDateDesktop } from '../field-date-desktop/field-date-desktop'
import { FieldDateMobile } from '../field-date-mobile/field-date-mobile'

export interface FieldDate extends FieldComponentProps<string> {
  openCalendar: string
}

export const FieldDate: FC<FieldDate> = ({ openCalendar, ...restProps }) =>
  (isMobile
    ? (
      <FieldDateMobile
        {...restProps}
      />
    )
    : (
      <FieldDateDesktop
        {...restProps}
        openCalendar={openCalendar}
      />
    ))
