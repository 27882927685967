import React, { FC } from 'react'
import { components, PlaceholderProps } from 'react-select'

import { OptionType } from '../../select'

import { SelectPlaceholderStyled } from './select-placeholder-styled'

export const SelectPlaceholder: FC<PlaceholderProps<OptionType>> = (props) => (
  <SelectPlaceholderStyled>
    <components.Placeholder
      {...props}
    />
  </SelectPlaceholderStyled>
)
