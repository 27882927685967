import { Translations } from '@jtb-don-fe/types'
import { isEmpty, notEmpty, pushFormAction } from '@jtb-don-fe/utils'
import React, { FC, FocusEvent, memo, useMemo, useRef, useState } from 'react'

import { Select } from '../../../select/root'
import { InputBaseProps } from '../../base/input-base'
import { Error } from '../../base/input-base-error-styled'

import { PhoneSelect } from './input-phone-select-styled'
import { MaskedStyledInput } from './input-phone-styled'
import { PhoneWrapper } from './input-phone-wrapper'

export interface InputPhoneViewProps extends InputBaseProps {
  translations: Translations
}

const PHONE_PREFIX = {
  CZ: '+420 ',
  SK: '+421 '
}

export const InputPhoneView: FC<InputPhoneViewProps> = memo((
  { onFocus, onBlur, onChange, dirty, errors, updating, ...props }
) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [phonePrefix, setPhonePrefix] = useState<string>(PHONE_PREFIX.CZ)
  const [active, setActive] = useState(false)
  const placeholder = `${phonePrefix}${props.placeholder}`
  const mask = phonePrefix === PHONE_PREFIX.CZ ? '+{42\\0} 000 000 000' : '+{421} 000 000 000'

  const onChangePrefixHandler = (value: string) => {
    setPhonePrefix(value)

    if (onChange && props.value) {
      onChange(`${value}${props.value.slice(phonePrefix.length, 16)}`)
    }
  }

  const options = useMemo(() => [
    {
      label: props.translations?.component?.select?.phonePrefix?.option?.CZ,
      value: PHONE_PREFIX.CZ
    },
    {
      label: props.translations?.component?.select?.phonePrefix?.option?.SK,
      value: PHONE_PREFIX.SK
    }
  ], [props.translations])

  const onFocusHandler = (event: FocusEvent) => {
    onFocus && onFocus(event)
    setActive(true)
    pushFormAction('form field entry', props.name, dirty ? errors?.length : 0)

    if (isEmpty(props.value)) {
      onChange && onChange(phonePrefix)

      if (inputRef.current) {
        inputRef.current.value = phonePrefix
      }
    }

    if (inputRef?.current) {
      inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length)
    }
  }

  const onBlurHandler = (event: FocusEvent) => {
    onBlur && onBlur(event)
    setActive(false)
    pushFormAction('form field exit', props.name, errors?.length)
  }

  const onAcceptHandler = (value: string) => {
    onChange && onChange(value)
  }

  const inputRefHandler = (el: HTMLInputElement) => {
    inputRef.current = el
  }

  const showError = notEmpty(errors) && (updating || dirty)
  const error = showError && notEmpty(errors) && !active

  return (
    <PhoneWrapper>
      <PhoneSelect>
        <Select
          phoneSelect
          value={phonePrefix}
          onChange={onChangePrefixHandler}
          name='phonePrefix'
          options={options}
          activeOuter={active || !!props.value}
        />
      </PhoneSelect>
      <MaskedStyledInput
        onBlur={onBlurHandler}
        onFocus={onFocusHandler}
        onAccept={onAcceptHandler}
        value={props.value}
        type='tel'
        active={active}
        placeholder={placeholder}
        errors={errors}
        showError={showError}
        inputRef={inputRefHandler}
        mask={mask}
      />
      {error && errors && (
        <Error
          data-test-id={`fieldError-${props.name}`}
          inputValue={props.value}
        >
          {errors[0].message}
        </Error>
      )}
    </PhoneWrapper>
  )
})
