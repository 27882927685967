import React, { FC } from 'react'

import { ToggleableSelect } from '../../select/toggleable'
import { Field, FieldComponentProps } from '../root/field'

type FieldTypeExtended = FieldComponentProps<string>['field'] & {
  options: Array<{
    value: string
    label: string
  }>
}

interface FieldToggleableSelectSpecificProps extends FieldComponentProps<string> {
  field: FieldTypeExtended
}

const Component = Field<typeof ToggleableSelect, FieldToggleableSelectSpecificProps>()
const components = { ...Component?.defaultProps?.components, InputComponent: ToggleableSelect }

export const FieldSelectToggleable: FC<FieldToggleableSelectSpecificProps> = (props) => (
  <Component
    {...props}
    components={components}
  />
)
