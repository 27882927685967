import styled from 'styled-components'
import { space } from 'styled-system'

export const LayoutTop = styled.div.attrs({
  padding: ['0', null, '0 3rem']
})`
  ${space};
  display: flex;
  justify-content: center;
  flex: 1;
  flex-direction: column;
`
