import { createGlobalStyle } from 'styled-components'

import GeometriaBold from '../../assets/fonts/Geometria-Bold.woff'
import GeometriaBoldItalic from '../../assets/fonts/Geometria-BoldItalic.woff'
import GeometriaExtraBold from '../../assets/fonts/Geometria-ExtraBold.woff'
import GeometriaExtraBoldItalic from '../../assets/fonts/Geometria-ExtraBoldItalic.woff'
import GeometriaExtraLight from '../../assets/fonts/Geometria-ExtraLight.woff'
import GeometriaExtraLightItalic from '../../assets/fonts/Geometria-ExtraLightItalic.woff'
import GeometriaHeavy from '../../assets/fonts/Geometria-Heavy.woff'
import GeometriaHeavyItalic from '../../assets/fonts/Geometria-HeavyItalic.woff'
import GeometriaItalic from '../../assets/fonts/Geometria-Italic.woff'
import GeometriaLight from '../../assets/fonts/Geometria-Light.woff'
import GeometriaLightItalic from '../../assets/fonts/Geometria-LightItalic.woff'
import GeometriaMedium from '../../assets/fonts/Geometria-Medium.woff'
import GeometriaMediumItalic from '../../assets/fonts/Geometria-MediumItalic.woff'
import GeometriaThin from '../../assets/fonts/Geometria-Thin.woff'
import GeometriaThinItalic from '../../assets/fonts/Geometria-ThinItalic.woff'
import Geometria from '../../assets/fonts/Geometria.woff'
import jatWoff from '../../assets/icons/jat.woff'
import { Font, fontMedium, fontTheme, colorTheme } from '../theme'

const fontFace = (path: string, { weight, style }: Font, family = 'Geometria') => `
  @font-face {
     font-family: '${family}';
     src: url('${path}');
     font-weight: ${weight};
     font-style: ${style};
     font-display: swap;
  }
`

export const GlobalStyles = createGlobalStyle`
  ${fontFace(Geometria, fontTheme.regular)}
  ${fontFace(GeometriaBoldItalic, fontTheme.boldItalic)}
  ${fontFace(GeometriaBold, fontTheme.bold)}
  ${fontFace(GeometriaExtraBoldItalic, fontTheme.extraBoldItalic)}
  ${fontFace(GeometriaExtraBold, fontTheme.extraBold)}
  ${fontFace(GeometriaExtraLightItalic, fontTheme.extraLightItalic)}
  ${fontFace(GeometriaExtraLight, fontTheme.extraLight)}
  ${fontFace(GeometriaHeavyItalic, fontTheme.heavyItalic)}
  ${fontFace(GeometriaHeavy, fontTheme.heavy)}
  ${fontFace(GeometriaItalic, fontTheme.italic)}
  ${fontFace(GeometriaLightItalic, fontTheme.lightItalic)}
  ${fontFace(GeometriaLight, fontTheme.light)}
  ${fontFace(GeometriaMediumItalic, fontTheme.mediumItalic)}
  ${fontFace(GeometriaMedium, fontTheme.medium)}
  ${fontFace(GeometriaThinItalic, fontTheme.thinItalic)}
  ${fontFace(GeometriaThin, fontTheme.thin)}

  @font-face {
    font-family: 'jat';
    src: url('${jatWoff}');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  * {
    box-sizing: border-box;
  }

  *:before, *:after {
    box-sizing: border-box;
  }

  input:focus, textarea:focus, select:focus{
    outline: none;
  }

  html {
    font-size: 62.5%;
  }

  body, html {
    font-family: 'Geometria';
    ${fontMedium};
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  body {
    margin: 0;
    font-size: 1.5rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: ${colorTheme.body};
  }
`
