import React, { ChangeEvent, FC, ReactNode, useCallback, useRef } from 'react'
import { useDropzone } from 'react-dropzone'
import { Translations } from '@jtb-don-fe/types'

import { ActionHandlers } from '../layout/drag-and-drop-layout'

import { DocumentAutoCapture } from './components/document-auto-capture'
import { Container, ContainerProps, DragAndDropType } from './components/dropzone-styled'
import { PdfPreview } from './components/pdf-preview'
import { Placeholder } from './components/placeholder-styled'
import { PreviewStyled } from './components/preview-styled'

export interface RenderLayoutProps {
  DragAndDropComponent: JSX.Element
  actionHandlers: ActionHandlers
}

export interface DragAndDropProps extends ContainerProps {
  allowedMimeTypes: Array<string>
  allowedFileSize: number
  validateFile: Function
  value: File
  onUpload: (file: File) => void
  placeholder?: ReactNode
  renderLayout: (renderProps: RenderLayoutProps) => JSX.Element
  onConfirm: () => void
  translations: Translations
}

export const DragAndDrop: FC<DragAndDropProps> = (props) => {
  const {
    value,
    onUpload,
    placeholder,
    type,
    setType,
    renderLayout,
    onConfirm,
    allowedMimeTypes,
    allowedFileSize,
    validateFile,
    translations
  } = props
  const onUploadHandler = useCallback(
    (file: File | Blob) => {
      onUpload(file as File)
      setType(DragAndDropType.Preview)
    },
    [onUpload, setType]
  )
  const onDropRejectedHandler = (fileRejections: File[]) => {
    const file = fileRejections[0]

    validateFile(file)
  }
  const onChangeFileHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target
    const file = files && files.length && files[0]

    if (file) {
      const isValid = validateFile(file)
      isValid && onUploadHandler(file)
    }
  }
  const onDropHandler = (acceptedFiles: File[]) => acceptedFiles.length > 0 && onUploadHandler(acceptedFiles[0])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropHandler,
    onDropRejected: onDropRejectedHandler,
    noClick: true,
    multiple: false,
    accept: allowedMimeTypes,
    maxSize: allowedFileSize
  })
  const inputRef = useRef<HTMLInputElement>(null)

  const WebcamComponent = (
    <DocumentAutoCapture
      translations={translations}
      onPhotoTaken={(file) => {
        const isValid = validateFile(file)
        isValid && onUploadHandler(file)
      }}
    />
  )

  const UploadComponent = (
    <>
      <Placeholder>{placeholder}</Placeholder>
      <input {...getInputProps()} onChange={onChangeFileHandler} data-test-id='dropZoneInput' ref={inputRef} />
    </>
  )

  const getComponent = () => {
    switch (type) {
      case DragAndDropType.Upload:
        return UploadComponent
      case DragAndDropType.Webcam:
        return WebcamComponent
      case DragAndDropType.Preview:
        if (value instanceof File && value.type === 'application/pdf') {
          return <PdfPreview pdf={value} />
        }

        return <PreviewStyled src={value && URL.createObjectURL(value)} />
      default:
        return null
    }
  }

  const DragAndDrop = (
    <Container
      {...(type === DragAndDropType.Upload ? getRootProps() : null)}
      type={type}
      isDragActive={isDragActive}
      data-test-id='dropZoneContainer'
    >
      {getComponent()}
    </Container>
  )

  const actionHandlers = {
    onUploadFile: () => {
      if (inputRef.current) {
        inputRef.current.click()
      }
    },
    onSubmitPreview: onConfirm,
    onMobileReset: () => {
      if (inputRef.current && inputRef.current.hasAttribute('capture')) {
        inputRef.current.removeAttribute('capture')
      }
    },
    onMobileStorage: () => {
      if (inputRef.current) {
        inputRef.current.removeAttribute('capture')
        inputRef.current.click()
      }
    }
  }

  return renderLayout({ DragAndDropComponent: DragAndDrop, actionHandlers })
}
