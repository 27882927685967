import DotObject from 'dot-object'
import i18next from 'i18next'
import { reduce } from 'ramda'

import localizationDefault from './localization.json'
import { getTranslations } from './translations.manager'

let localizationCs = localizationDefault.cs

export const fetchLocalizations = async () => {
  const response = await fetch('/api/localization')

  if (response.status === 200) {
    const data = await response.json()

    if (data?.cs) {
      localizationCs = reduce(
        (accumulator, key) => ({
          ...accumulator,
          [key]: data.cs[key] || localizationCs[key]
        }),
        {},
        Object.keys(localizationCs)
      )
    }
  }

  i18next.addResourceBundle('cs', 'translations', DotObject.object(localizationCs))

  return getTranslations('welcome')()
}
