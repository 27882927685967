import { PDFDocumentProxy } from 'pdfjs-dist'
import React, { FC, useCallback, useRef, useState } from 'react'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'

import { Loader } from '../../../..'

import { PdfPreviewWrapper, ReactPdfControls, LoaderWrapper } from './pdf-preview-wrapper-styled'

interface PdfPreviewProps {
  pdf?: File
}

export const PdfPreview: FC<PdfPreviewProps> = ({ pdf }) => {
  const file = useRef(URL.createObjectURL(pdf))
  const [numPages, setNumPages] = useState<number | null>(null)
  const [pageNumber, setPageNumber] = useState(1)

  const onDocumentLoadSuccess = useCallback(({ numPages }: PDFDocumentProxy) => {
    setNumPages(numPages)
    setPageNumber(1)
  }, [])

  const changePage = useCallback((offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }, [])

  const previousPage = useCallback(() => {
    changePage(-1)
  }, [changePage])

  const nextPage = useCallback(() => {
    changePage(1)
  }, [changePage])

  return (
    <>
      <PdfPreviewWrapper>
        <Document
          file={file.current}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<LoaderWrapper><Loader /></LoaderWrapper>}
        >
          <Page
            pageNumber={pageNumber}
          />
        </Document>
      </PdfPreviewWrapper>
      <ReactPdfControls>
        <button
          type='button'
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          ‹
        </button>
        <span>
          {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
        </span>
        <button
          type='button'
          disabled={numPages !== null && pageNumber >= numPages}
          onClick={nextPage}
        >
          ›
        </button>
      </ReactPdfControls>
    </>
  )
}
