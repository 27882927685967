import React, { FC, ReactNode } from 'react'

import { Button } from '../components/button-styled'

import { TooltipStyled } from './components/tooltip-styled'

interface ButtonTooltipProps {
  content: ReactNode
  name: string
}

export const ButtonTooltipHover: FC<ButtonTooltipProps> = ({ content, name }) => (
  <TooltipStyled
    content={content}
    data-test-id={`buttonTooltipHover-${name}`}
  >
    <Button />
  </TooltipStyled>
)
