import React, { FC } from 'react'

import { Input } from '../../input/root'
import { Field, FieldComponentProps } from '../root/field'

import { ButtonLinkStyled } from './components/button-link-styled'
import { FieldLabelStyled } from './components/field-label-styled'

interface FieldAccountNumberSpecificProps extends FieldComponentProps<string> {
  onSwitchPrefix: () => void
}

const Component = Field<typeof Input, FieldComponentProps<string>>()
const components = { ...Component?.defaultProps?.components, InputComponent: Input }

export const FieldAccountNumber: FC<FieldAccountNumberSpecificProps> = ({ onSwitchPrefix, ...props }) => {
  const onChange = (value: string) => {
    const valueWithDigitsOnly = value.replace(/\D/g, '')

    if (props.onChange) {
      props.onChange(valueWithDigitsOnly)
    }
  }

  const Label = () => (
    <FieldLabelStyled
      as='label'
      data-test-id={`fieldLabel-${props.field.name}`}
      htmlFor={props.field.name}
    >
      {props.translations?.[props.field.name]?.label}
      <ButtonLinkStyled
        name={props.field.name}
        onClick={onSwitchPrefix}
      >
        {props.translations?.button?.switchPrefix}
      </ButtonLinkStyled>
    </FieldLabelStyled>
  )

  return (
    <Component
      {...props}
      onChange={onChange}
      components={{ ...components, FieldLabelComponent: Label }}
      field={{ ...props.field, type: 'tel' }}
    />
  )
}
