import React, { FC } from 'react'

import { InputPhone } from '../../input/phone'
import { Field, FieldComponentProps } from '../root/field'

const Component = Field<typeof InputPhone, FieldComponentProps<string>>()
const components = { ...Component?.defaultProps?.components, InputComponent: InputPhone }

export const FieldPhone: FC<FieldComponentProps<string>> = (props) => (
  <Component
    {...props}
    components={components}
  />
)
