/* eslint-disable react/no-danger */
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { CookiesBanner, CookiesModal, createFingerprint } from '@jtb-don-fe/ui'
import { v4 as uuidv4 } from 'uuid'

import { sanitize } from '../../logic/core/sanitize'
import { useGoogleTagManager } from '../../logic/hook/google-tag-manager'
import { pushPageVisited, pushButtonClicked } from '../../logic/utils/google-tag-manager/google-tag-manager'

export const AppContainer = ({ translations, configuration }) => {
  const [areAnalyticalCookiesEnabled, setAreAnalyticalCookiesEnabled] = useState(() =>
    JSON.parse(localStorage.getItem('areAnalyticalCookiesEnabled'))
  )
  const [isCookiesModalOpen, setIsCookiesModalOpen] = useState(false)

  const loaded = useGoogleTagManager(
    configuration?.apiKey?.googleTagManagerKey,
    areAnalyticalCookiesEnabled === null ? true : !areAnalyticalCookiesEnabled
  )

  const setAnalyticalCookies = async (areAnalyticalCookiesEnabled) => {
    setAreAnalyticalCookiesEnabled(areAnalyticalCookiesEnabled)
    localStorage.setItem('areAnalyticalCookiesEnabled', areAnalyticalCookiesEnabled)

    await fetch('/api/clientInfo/cookies', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        browserFingerprint: createFingerprint(),
        consent: areAnalyticalCookiesEnabled
      })
    })
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const dipOnly = searchParams.get('dipOnly')

    Cookies.set('dipOnly', !!dipOnly)
  }, [])

  useEffect(() => {
    if (loaded) {
      const sessionIdInitial = uuidv4()

      Cookies.set('sessionIdInitial', sessionIdInitial)
      pushPageVisited(translations.title, sessionIdInitial)
    }
  }, [loaded, translations.title])

  return (
    <>
      <div className='layout'>
        <div className='layout--top'>
          <div className='formbox'>
            <div className='formbox--scrollable no_mCustomScrollbar' data-mcs-theme='minimal-dark'>
              <div className='in'>
                <h1 dangerouslySetInnerHTML={sanitize(translations.title, true)} />

                <p className='text-left' dangerouslySetInnerHTML={sanitize(translations.info, true)} />

                <p className='text-left' dangerouslySetInnerHTML={sanitize(translations.onlineForm, true)} />

                <div className='space-30' />

                <div className='infosteps'>
                  <div className='infostep--item'>
                    <div className='infostep--img'>
                      <img
                        src='/welcome/public/img/icons/OP-pas.svg'
                        alt={sanitize(translations.infoStep.idPassport)}
                        width={133}
                        height={69}
                      />
                    </div>
                    <div
                      className='infostep--txt'
                      dangerouslySetInnerHTML={sanitize(translations.infoStep.idPassport, true)}
                    />
                  </div>
                  <div className='infostep--item'>
                    <div className='infostep--img'>
                      <img
                        src='/welcome/public/img/icons/Phone-mail.svg'
                        alt={sanitize(translations.infoStep.phoneMail)}
                        width={51}
                        height={80}
                      />
                    </div>
                    <div
                      className='infostep--txt'
                      dangerouslySetInnerHTML={sanitize(translations.infoStep.phoneMail, true)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='formbox--bottom'>
              <div className='in'>
                <div className='row'>
                  <div className='form-group offset-sm-6 offset-sm-6 col-sm-6 col-xs-12'>
                    <a
                      className='button__next'
                      href='/web/'
                      onClick={() => {
                        if (areAnalyticalCookiesEnabled) {
                          Cookies.set('referrer', Cookies.get('referrerFromWeb') || document.referrer)
                        }
                        Cookies.remove('referrerFromWeb')

                        Cookies.set('welcome_pass', 'ok')
                        pushButtonClicked('continue')
                      }}
                    >
                      {translations.button.submit}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='layout--bottom' style={{ zIndex: -1 }}>
        <div className='layout--bottom--container layout--flex-center'>
          <div className='layout--bottom--logo'>
            <a href='https://www.jtbank.cz/' title='J&amp;T Banka' target='_blank' rel='noopener noreferrer'>
              <img src='/welcome/public/img/logo/jat-banka.svg' alt='J&amp;T Banka' width={118} height={17} />
            </a>
          </div>
        </div>
      </div>

      {areAnalyticalCookiesEnabled === null && !isCookiesModalOpen && (
        <CookiesBanner
          translations={translations.cookies.banner}
          onCookiesSettings={() => setIsCookiesModalOpen(true)}
          onRefuseCookies={() => setAnalyticalCookies(false)}
          onAllowCookies={() => setAnalyticalCookies(true)}
        />
      )}

      {areAnalyticalCookiesEnabled === null && (
        <CookiesModal
          isOpen={isCookiesModalOpen}
          onRequestClose={() => setIsCookiesModalOpen(false)}
          translations={translations.cookies.modal}
          onAcceptSelection={(withAnalytics) => setAnalyticalCookies(withAnalytics)}
          onRefuseCookies={() => setAnalyticalCookies(false)}
          onAllowCookies={() => setAnalyticalCookies(true)}
        />
      )}
    </>
  )
}
