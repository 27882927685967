import { FC } from 'react'
import styled from 'styled-components'

import { sanitizedContentClass } from '../../components/sanitized-content-class'
import { TextCss } from '../../root/text'
import { Paragraph } from '../paragraph'

export const ParagraphStyled: FC<Paragraph> = styled.p`
  font-size: 1.5rem;
  line-height: 1.6em;
  margin: 2.7rem 0;
  ${TextCss}
  ${sanitizedContentClass}
`
