import { String } from '@jtb-don-fe/utils'
import { all } from 'ramda'
import React, { FC, memo, useRef, useState } from 'react'

import { Input } from '../../input/root'
import { Field, FieldComponentProps } from '../root/field'

interface FieldTextSpecificProps extends FieldComponentProps<string> {
  capitalCase?: boolean
}

const Component = Field<typeof Input, FieldComponentProps<string>>()
const components = { ...Component?.defaultProps?.components, InputComponent: Input }

const FieldTextBase: FC<FieldTextSpecificProps> = (props) => {
  const [position, setPosition] = useState()
  const inputRef = useRef<HTMLInputElement>(null)
  const { field, onChange, capitalCase = true } = props
  const refs = { ref: inputRef }
  const onChangeHandler = (value: string) => {
    if (onChange) {
      const modifiers = field?.modifiers || []
      const validators = field?.validators || []
      const validationNotValid = !all((validator: Function) => validator(value), validators)

      if (validationNotValid) {
        return
      }

      if (capitalCase) {
        modifiers.push(String.capitalize)
      }

      const modifiedValue = modifiers.reduce((value, modifier) => modifier(value), value)

      onChange(modifiedValue)

      inputRef.current && setPosition(inputRef.current.selectionStart)
    }
  }

  return (
    <Component
      {...props}
      field={{ ...field, type: field.type || 'text', position, inputRef, refs }}
      onChange={onChangeHandler}
      components={components}
    />
  )
}

export const FieldText = memo(FieldTextBase)
