import { String } from '@jtb-don-fe/utils'
import { all, toUpper } from 'ramda'
import React, { FC, memo, useRef, useState, ClipboardEvent } from 'react'

import { Input } from '../../input/root'
import { Field, FieldComponentProps } from '../root/field'

interface FieldTextSpecificProps extends FieldComponentProps<string> {
  capitalCase?: boolean
}

const Component = Field<typeof Input, FieldComponentProps<string>>()
const components = { ...Component?.defaultProps?.components, InputComponent: Input }

const FieldTextBase: FC<FieldTextSpecificProps> = (props) => {
  const [position, setPosition] = useState()
  const inputRef = useRef<HTMLInputElement>(null)
  const { field, onChange } = props
  const refs = { ref: inputRef }

  const getModifiers = () => [String.capitalize, toUpper]
  const getValidators = () => field?.validators || []

  const modifyValue = (
    value: string,
    modifiers: Array<(value: string) => string>,
    onChange: (newValue: string) => void
  ) => {
    const modifiedValue = modifiers.reduce((value, modifier) => modifier(value), value)

    onChange(modifiedValue)

    inputRef.current && setPosition(inputRef.current.selectionStart)
  }

  const onChangeHandler = (value: string) => {
    if (onChange) {
      const modifiers = getModifiers()
      const validators = getValidators()

      const validationNotValid = !all((validator: Function) => validator(value), validators)

      if (validationNotValid) {
        return
      }

      modifyValue(value, modifiers, onChange)
    }
  }

  const onPasteHandler = (value: string, event: ClipboardEvent) => {
    if (onChange) {
      const modifiers = getModifiers()
      const validators = getValidators()

      const validationNotValid = !all((validator: Function) => validator(value), validators)

      if (validationNotValid) {
        event.preventDefault()

        return
      }

      modifyValue(value, modifiers, onChange)
    }
  }

  return (
    <Component
      {...props}
      field={{ ...field, type: field.type || 'text', position, inputRef, refs }}
      onChange={onChangeHandler}
      onPaste={onPasteHandler}
      components={components}
    />
  )
}

export const FieldDocumentNumber = memo(FieldTextBase)
