import { css } from 'styled-components'

type FontStyle = 'normal' | 'italic' | 'oblique' | 'initial' | 'inherit'

export type Font = {
  weight: number
  style: FontStyle
}

const font = (weight: number, style: FontStyle): Font => ({ weight, style })

export const fontTheme = {
  regular: font(400, 'normal'),
  bold: font(700, 'normal'),
  italic: font(400, 'italic'),
  boldItalic: font(700, 'italic'),
  extraBold: font(800, 'normal'),
  extraBoldItalic: font(800, 'italic'),
  extraLight: font(200, 'normal'),
  extraLightItalic: font(200, 'italic'),
  heavy: font(900, 'normal'),
  heavyItalic: font(900, 'italic'),
  light: font(300, 'normal'),
  lightItalic: font(300, 'italic'),
  medium: font(500, 'normal'),
  mediumItalic: font(500, 'italic'),
  thin: font(100, 'normal'),
  thinItalic: font(100, 'italic')
}

type FontTheme = typeof fontTheme

const fontStyled = (fontType: keyof FontTheme) => css`
  font-weight: ${fontTheme[fontType].weight};
  font-style: ${fontTheme[fontType].style};
`

export const fontRegular = fontStyled('regular')
export const fontBold = fontStyled('bold')
export const fontItalic = fontStyled('italic')
export const fontBoldItalic = fontStyled('boldItalic')
export const fontExtraBold = fontStyled('extraBold')
export const fontExtraBoldItalic = fontStyled('extraBoldItalic')
export const fontExtraLight = fontStyled('extraLight')
export const fontExtraLightItalic = fontStyled('extraLightItalic')
export const fontHeavy = fontStyled('heavy')
export const fontHeavyItalic = fontStyled('heavyItalic')
export const fontLight = fontStyled('light')
export const fontLightItalic = fontStyled('lightItalic')
export const fontMedium = fontStyled('medium')
export const fontMediumItalic = fontStyled('mediumItalic')
export const fontThin = fontStyled('thin')
export const fontThinItalic = fontStyled('thinItalic')
