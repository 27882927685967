import React, { FC } from 'react'

import { InputSms } from '../../input/sms'
import { Field, FieldComponentProps } from '../root/field'

interface FieldSmsSpecificProps extends FieldComponentProps<string> {
  specificErrors: Array<{ code: string; message: string }>
}

const Component = Field<typeof InputSms, FieldSmsSpecificProps>()
const components = { ...Component?.defaultProps?.components, InputComponent: InputSms }

export const FieldSms: FC<FieldSmsSpecificProps> = (props) => (
  <Component
    {...props}
    components={components}
  />
)
