import { Translations } from '@jtb-don-fe/types'
import { notEmpty, pushFormAction } from '@jtb-don-fe/utils'
import React, { FC, useEffect } from 'react'

import Phone from '../../../assets/icons/mt.svg'
import { FieldSms, FieldProps, ButtonLinkTransparent } from '../../generic'

import { Icon } from './components/icon'
import { LeftBox } from './components/left-box'
import { RightBox } from './components/right-box'
import { Wrapper } from './components/wrapper'

interface SmsVerificationInputProps<T = string> {
  translations: Translations
  smsField: FieldProps<T>
  buttonLabel: JSX.Element
  onChange: (value: T) => void
  onResend: () => void
  specificErrors: Array<{ code: string; message: string }>
  disabled?: boolean
}

export const SmsVerification: FC<SmsVerificationInputProps> = (props) => {
  const { translations, smsField, buttonLabel, onChange, onResend, disabled, specificErrors } = props

  const showSpecificError = notEmpty(specificErrors) && !smsField.value?.match(/\d+/)

  useEffect(() => {
    if (showSpecificError) {
      pushFormAction('form field exit', smsField.name, 1, specificErrors[0].message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSpecificError])

  return (
    <Wrapper>
      <LeftBox
        showSpecificError={showSpecificError}
      >
        <Icon
          src={Phone}
          alt=''
        />
      </LeftBox>
      <FieldSms
        field={smsField}
        onChange={onChange}
        translations={translations}
        specificErrors={specificErrors}
      />
      <RightBox
        showSpecificError={showSpecificError}
      >
        <ButtonLinkTransparent
          onClick={onResend}
          disabled={disabled}
          name='send-again'
          type={disabled ? 'button' : 'submit'}
        >
          {buttonLabel}
        </ButtonLinkTransparent>
      </RightBox>
    </Wrapper>
  )
}
