import styled, { css } from 'styled-components'

import { Flex } from '../../../generic/layout/flex'
import {
  identityPaperItemActiveIconBackgroundColor, identityPaperItemActiveIconBorderColor, identityPaperItemActiveIconColor
} from '../../../theme'

export interface PaperItemStyledProps {
  active?: boolean
}

export const paperItemActiveCss = ({ active }: PaperItemStyledProps) => active && css`
  &:before {
    font-family: jat;
    font-weight: 400;
    content: 'h';
    position: absolute;
    top: 0;
    height: 2.4rem;
    line-height: 2.4rem;
    font-size: 2.4rem;
    color: ${identityPaperItemActiveIconColor};
    transform: translate(-50%,-50%);
    border-left: 3px solid ${identityPaperItemActiveIconBorderColor};
    left: 50%;
    background-color: ${identityPaperItemActiveIconBackgroundColor};
    border-right: 3px solid ${identityPaperItemActiveIconBorderColor};
    text-transform: none;
    text-align: center;
  }
`

export const PaperItemStyled = styled(Flex).attrs({
  flexDirection: 'column',
  padding: '4rem 3rem 1.5rem',
  flex: 1,
  justifyContent: 'center'
})`
  ${paperItemActiveCss}
`
