import { lighten } from 'polished'
import { ThemeProps } from 'styled-components'

import { Theme } from './index'

export const colorTheme = {
  primary: '#036c68',
  primaryActive: '#000000',
  primaryContra: '#ffffff',
  text: '#000000',
  label: '#000000',
  error: '#e02828',
  warn: '#fd9526',
  separator: '#d6d6d6',
  lightBackground: '#f2f2f2',
  placeholder: '#a0a0a0',
  body: '#ededed'
}

type ColorTheme = typeof colorTheme
type Color = keyof ColorTheme
type Modifier = (value: string) => string

const color = (type: Color, modifier?: Modifier) => (props: ThemeProps<Theme>): string => (modifier
  ? modifier(props.theme.color[type])
  : props.theme.color[type])

const lightenInactive = (type: string) => lighten(0.626, type)

export const textColor = color('text')

export const inputTextColor = color('text')
export const inputLabelColor = color('label')
export const inputPlaceholderColor = color('placeholder')
export const inputBackgroundColor = color('lightBackground')
export const inputBorderColor = color('separator')
export const inputBorderActiveColor = color('primary')
export const inputBorderErrorColor = color('error')
export const inputBorderWarnColor = color('warn')
export const inputTextErrorColor = color('error')

export const buttonPrimaryTextColor = color('primaryContra')
export const buttonPrimaryBackgroundColor = color('primary')
export const buttonPrimaryBackgroundActiveColor = color('primaryActive')

export const buttonSecondaryTextColor = color('primary')
export const buttonSecondaryBorderColor = color('primary')
export const buttonSecondaryBackgroundColor = color('primaryContra')
export const buttonSecondaryTextActiveColor = color('primaryContra')
export const buttonSecondaryBackgroundActiveColor = color('primaryActive')

export const buttonLinkTransparentTextColor = color('primary')
export const buttonLinkTransparentBackgroundColor = color('primaryContra')
export const buttonLinkTransparentTextActiveColor = color('primaryContra')
export const buttonLinkTransparentBorderActiveColor = color('text')
export const buttonLinkTransparentBackgroundActiveColor = color('text')

export const buttonLinkTextColor = color('primary')

export const buttonDownloadTextColor = color('primary')
export const buttonDownloadTextActiveColor = color('primaryActive')
export const buttonDownloadBackgroundColor = color('lightBackground')

export const checkboxBackgroundColor = color('lightBackground')
export const checkboxBackgroundActiveColor = color('primaryActive')
export const checkboxBorderColor = color('separator')
export const checkboxTextColor = color('text')
export const checkboxIconColor = color('separator')
export const checkboxIconActiveColor = color('primaryContra')
export const checkboxTextActiveColor = color('primaryContra')

export const checkboxButtonBorderActiveColor = color('primaryActive')

export const radioButtonTextColor = color('text')
export const radioButtonBackgroundColor = color('lightBackground')
export const radioButtonTextActiveColor = color('primaryContra')
export const radioButtonBackgroundActiveColor = color('primaryActive')
export const radioButtonTextNothingCheckedColor = color('text', lightenInactive)
export const radioButtonCircleBorderColor = color('placeholder')
export const radioButtonCircleActiveColor = color('primaryContra')

export const separatorVerticalColor = color('separator')
export const separatorHorizontalColor = color('separator')

export const tooltipTextColor = color('primaryContra')
export const tooltipBackgroundColor = color('primaryActive')

export const selectBackgroundColor = color('primaryContra')
export const selectNonSelectedBackgroundColor = color('lightBackground')
export const selectOptionBackgroundColor = color('primaryContra')
export const selectOptionBackgroundActiveColor = color('primary')
export const selectOptionBackgroundHoverColor = color('lightBackground')
export const selectTextColor = color('text')
export const selectTextActiveColor = color('primaryContra')
export const selectIndicatorColor = color('primary')

export const formBoxBackgroundColor = color('primaryContra')
export const formBoxColor = color('text')

export const footerTextColor = color('placeholder')

export const linkColor = color('primary')

export const layoutColor = color('primaryContra')

export const headerListItemColor = color('primary')
export const headerListItemDisabledColor = color('placeholder')
export const headerBackgroundColor = color('body')

export const progressBarBackgroundColor = color('primaryContra')
export const progressBarStrokeBackgroundColor = color('primary')

export const loaderItemBackgroundColor = color('primary')

export const dragAndDropSeparatorColor = color('separator')
export const dragAndDropSeparatorOnDragColor = color('primary')
export const dragAndDropBackgroundOnDragColor = color('lightBackground')
export const dragAndDropPlaceholderColor = color('placeholder')

export const buttonGroupSeparatorColor = color('separator')

export const identityPaperBorderColor = color('separator')
export const identityPaperSeparatorColor = color('separator')
export const identityPaperTextColor = color('label')
export const identityPaperItemActiveIconColor = color('primary')
export const identityPaperItemActiveIconBackgroundColor = color('primaryContra')
export const identityPaperItemActiveIconBorderColor = color('primaryContra')
export const identityPaperSeparatorTextColor = color('placeholder')
export const identityPaperSeparatorBackgroundColor = color('primaryContra')
export const identityPaperDoneColor = color('primary')

export const phoneUploadMainSeparatorColor = color('separator')
export const phoneUploadMainSeparatorTextColor = color('separator')
export const phoneUploadMainSeparatorTextBackgroundColor = color('primaryContra')

export const listItemStyleBackgroundColor = color('primary')

export const finishIdProgressDoneColor = color('primary')
export const finishIdProgressWaitingColor = color('placeholder')

export const questionnaireMoreOptionsColor = color('primary')
