import React from 'react'
import { components, InputProps } from 'react-select'

export const SelectInput = (props: InputProps) => {
  const inputProps = { ...props, type: 'text' }

  return (
    <components.Input
      {...inputProps}
    />
  )
}
