import styled from 'styled-components'

import { linkColor } from '../../../../../../theme'

export const ButtonLinkStyled = styled.button`
  color: ${linkColor};
  text-decoration: underline;
  border: 0;
  padding: 0;
  font-size: 1.5rem;
  background-color: transparent;
`
