import { CSSProperties } from 'react'
import styled from 'styled-components'
import {
  layout,
  space,
  flexbox,
  style,
  border,
  position,
  zIndex,
  overflow,
  typography,
  LayoutProps,
  SpaceProps,
  FlexboxProps,
  PositionProps,
  ZIndexProps,
  OverflowProps,
  TypographyProps
} from 'styled-system'

type FlexType = LayoutProps &
  SpaceProps &
  FlexboxProps &
  PositionProps &
  ZIndexProps &
  OverflowProps &
  TypographyProps &
  Pick<CSSProperties, 'gap'>

const background = style({
  prop: 'background',
  cssProperty: 'background',
  key: 'colors'
})

export const Flex = styled.div<FlexType>`
  display: flex;
  gap: ${({ gap }) => gap};
  ${position};
  ${zIndex};
  ${space};
  ${layout};
  ${flexbox};
  ${background};
  ${border};
  ${overflow};
  ${typography};
`
