import { omit } from 'ramda'
import React, { FC } from 'react'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import styled from 'styled-components'

import { inputBaseStyledCss, InputBaseStyledProps } from '../../base/input-base-styled'

const FilteredSmsInput: FC<InputBaseStyledProps & NumberFormatProps> = (props) => {
  const restProps = omit([
    'active', 'errors', 'showError', 'touched', 'focused', 'updating', 'dirty', 'config', 'translations',
    'specificErrors'
  ], props)

  return (
    <NumberFormat
      {...restProps}
    />
  )
}

export const InputSmsStyled = styled(FilteredSmsInput)<InputBaseStyledProps>`
  ${inputBaseStyledCss};
  letter-spacing: 1rem;
`
