import React, { FC, ReactNode } from 'react'

import { MainWrapper } from './components/main-wrapper'
import { SideMarginWrapper } from './components/side-margin-wrapper'

interface ButtonGroupProps {
  children: ReactNode | ReactNode[]
  multiColumns?: boolean
}

export const ButtonGroup: FC<ButtonGroupProps> = ({ children, multiColumns }) => {
  const multiColumnsDefault = (children && Array.isArray(children)) ? children.length > 1 : false

  return (
    <MainWrapper>
      <SideMarginWrapper
        multiColumns={multiColumns || multiColumnsDefault}
      >
        {children}
      </SideMarginWrapper>
    </MainWrapper>
  )
}
