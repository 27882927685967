import { isEmpty } from '@jtb-don-fe/utils'
import styled from 'styled-components'

import { inputTextErrorColor, fontRegular } from '../../../../theme'

interface ErrorProps {
  inputValue?: string
}

export const Error = styled.div<ErrorProps>`
  right: 2px;
  top: 0.3rem;
  position: absolute;
  height: calc(100% - 0.6rem);
  pointer-events: none;
  width: auto;
  display: flex;
  align-items: center;
  color: ${inputTextErrorColor};
  font-size: 1.4rem;
  border-radius: 0;
  line-height: 1.3rem;
  padding: 1.1rem 1.5rem 1.1rem 2.5rem;
  opacity: 1;
  transition: background-color 150ms linear, opacity 150ms linear;
  background: ${({ inputValue }) => (isEmpty(inputValue)
    ? 'linear-gradient(90deg, rgba(242, 242, 242, 0) 0%, rgba(242, 242, 242, 1) 15%, rgba(242, 242, 242, 1) 100%)'
    : 'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 1) 100%)'
  )};
  ${fontRegular}

  &:after {
    content: 'f';
    font-family: 'jat';
    font-weight: normal;
    text-transform: none;
    margin-left: 0.9rem;
    font-size: 1.6rem;
  }
`
