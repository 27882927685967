import React from 'react'
import { Translations } from '@jtb-don-fe/types'

import { Paragraph, Text } from '../../text'
import { Flex } from '../../layout'

import { CookiesTable } from './cookies-table'

interface FunctionalCookiesTabProps {
  translations: Translations
}

export const FunctionalCookiesTab = ({ translations }: FunctionalCookiesTabProps) => (
  <div style={{ padding: '1rem', width: '80%' }}>
    <Flex alignItems='center' justifyContent='space-between'>
      <Text as='h3' fontSize='1.7em'>
        {translations.title}
      </Text>
      <Text>{translations.alwaysActive}</Text>
    </Flex>
    <Paragraph mt='1rem'>{translations.description}</Paragraph>
    <CookiesTable translations={translations.table} />
  </div>
)
