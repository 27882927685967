import styled from 'styled-components'

import { Flex } from '../../../generic/layout/flex'

interface RightBox {
  showSpecificError: boolean
}

export const RightBox = styled(Flex).attrs<RightBox>(({ showSpecificError }) => ({
  display: ['none', null, 'block'],
  flex: [1, null, '0 0 18rem'],
  alignSelf: showSpecificError ? 'center' : 'flex-end'
}))<RightBox>``
