import Tooltip from 'react-tooltip-lite'
import styled from 'styled-components'

import { fontRegular, tooltipBackgroundColor, tooltipTextColor } from '../../../../../../theme'

export const TooltipStyled = styled(Tooltip).attrs(() => ({
  direction: 'right',
  distance: 20,
  padding: '1.6rem 2rem'
}))`
  display: inline;

  .react-tooltip-lite {
    color: ${tooltipTextColor};
    background-color: ${tooltipBackgroundColor};
    width: 30rem;
    border-radius: 0.4rem;
    font-size: 1rem;
    -webkit-font-smoothing: initial;

      p {
        color: #ffffff;
        display: inline;
        margin: 0.5rem 0;
        font-size: 1.1rem;
        padding: 0;
        ${fontRegular};
        line-height: 1.6em;
        text-align: left;
        letter-spacing: 1px;

      }
  }

  .react-tooltip-lite-arrow {
    color: ${tooltipBackgroundColor};
  }
`
