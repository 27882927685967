import styled from 'styled-components'

import { footerTextColor, fontRegular } from '../../../../../../theme'

export const FooterTextStyled = styled.div`
  width: 100%;
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin: 1.7rem 0;
  text-align: center;
  padding: 0 3rem;
  ${fontRegular};
  color: ${footerTextColor};
`
