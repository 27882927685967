import React, { FC } from 'react'

import { SanitizeHtml } from '../../../../text'

interface PlaceholderProps {
  variableText?: string
}

export const Placeholder: FC<PlaceholderProps> = ({ variableText }) => (
  <SanitizeHtml>
    {variableText}
  </SanitizeHtml>
)
