import React, { FC, ReactNode } from 'react'

import { Checkbox } from '../../input/checkbox'
import { Field, FieldComponentProps } from '../root/field'

interface FieldCheckboxSpecificProps extends FieldComponentProps<boolean> {
  labelComponent?: ReactNode
}

const Component = Field<typeof Checkbox, FieldCheckboxSpecificProps>()
const components = { ...Component?.defaultProps?.components, InputComponent: Checkbox }

export const FieldCheckbox: FC<FieldCheckboxSpecificProps> = (props) => (
  <Component
    {...props}
    components={components}
  />
)
