import styled from 'styled-components'

import { fontMedium, inputTextColor } from '../../../../../../theme'

interface SelectValueContainerStyledProps {
  toggleable: boolean
  isSearchable?: boolean
}

export const SelectValueContainerStyled = styled.div<SelectValueContainerStyledProps>`
  font-size: 1.5rem;
  ${fontMedium};
  color: ${inputTextColor};
  padding: ${({ toggleable, isSearchable }) => {
    if (toggleable) {
      return '.7rem .75rem'
    }

    if (isSearchable) {
      return '1.3rem .75rem'
    }

    return '0 .75rem'
  }};
  display: flex;
  align-items: center;
  height: 100%;
`
