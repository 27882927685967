import React from 'react'

import { onClickButton } from '../../../../utils'
import { ButtonProps } from '../components/button-styled'

import { ButtonSecondaryStyled } from './components/button-secondary-styled'

export const ButtonSecondary: React.FC<ButtonProps> = ({ children, onClick, formValid, ...props }) => (
  <ButtonSecondaryStyled
    {...props}
    onClick={onClickButton(props.name, onClick, formValid)}
    data-test-id={`buttonSecondary-${props.name}`}
    type='button'
  >
    {children}
  </ButtonSecondaryStyled>
)
