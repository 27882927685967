import { addIndex, map } from 'ramda'
import React from 'react'

import { Flex } from '../../layout/flex/flex'

import { LoaderItemStyled } from './components/loader-item-styled'
import { LoaderItemsWrapperStyled } from './components/loader-items-wrapper-styled'
import { LoaderStyled } from './components/loader-styled'

export const Loader = () => (
  <Flex
    justifyContent='center'
  >
    <LoaderStyled
      data-test-id='loader'
    >
      <LoaderItemsWrapperStyled>
        {addIndex(map)((val, idx) => (
          <LoaderItemStyled
            key={idx}
          />
        ), new Array(9))}
      </LoaderItemsWrapperStyled>
    </LoaderStyled>
  </Flex>
)
