import { ThemeProps } from 'styled-components'

import logoJtb from '../../assets/images/logo.svg'

import { Theme } from './index'

export const imageTheme = {
  logo: logoJtb
}

type ImageTheme = typeof imageTheme

type Image = keyof ImageTheme

const image = (type: Image) => (props: ThemeProps<Theme>) => props.theme.image[type]

export const logo = image('logo')
