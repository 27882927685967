import DOMPurify from 'dompurify'
import { decode } from 'he'

export const sanitize = (text: string, dangerous = false) => {
  const sanitized = DOMPurify.sanitize(decode(text), {
    ALLOWED_TAGS: ['a', 'strong', 'br', 'ul', 'ol', 'li', 'span'],
    ALLOWED_ATTR: ['href', 'target', 'class', 'src', 'style']
  })

  return dangerous ? { __html: sanitized } : sanitized
}
