import styled from 'styled-components'

import { fontMedium } from '../../../../../../theme'

export const SelectPlaceholderStyled = styled.div`
  > div {
    color: #a0a0a0;
    font-size: 1.5rem;
    ${fontMedium};
  }
`
