import { TextDecoder, TextEncoder } from 'text-encoding-shim'

declare global {
  interface Window {
    TextDecoder: TextDecoder
    TextEncoder: TextEncoder
  }
}

export const internetExplorerTextEncoder = async() => {
  if (!window.TextDecoder || !window.TextEncoder) {
    const TextEncodingShim = await import('text-encoding-shim')

    // Fix for EDGE (version < 44 (18 engine version))
    window.TextDecoder = window.TextDecoder || TextEncodingShim.TextDecoder
    window.TextEncoder = window.TextEncoder || TextEncodingShim.TextEncoder
  }
}
