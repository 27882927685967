import styled from 'styled-components'
import { space } from 'styled-system'

export const HeaderList = styled.ul.attrs({
  padding: ['0.4rem 0 0', null, null, '0.6rem 1.5rem']
})`
  ${space};
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin: 0;
  list-style: none;
  user-select: none;
  z-index: 90;
  counter-reset: section;
`
