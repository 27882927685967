import { ThemeProps } from 'styled-components'

import { Theme } from '../../theme'

export interface TypeToColor extends ThemeProps<Theme> {
  type: string
}

export const typeToColor = ({ type, theme }: TypeToColor) => {
  switch (type) {
    case 'info':
      return theme.color.label
    case 'error':
      return theme.color.error
    default:
      return theme.color.text
  }
}
