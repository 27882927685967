/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Translations } from '@jtb-don-fe/types'
import '@innovatrics/dot-document-auto-capture'

import { DocumentCameraProps } from './document-auto-capture-types'

const AutoCaptureWrapper = styled.div`
  height: 41rem;
  width: 100%;

  @media all and (min-width: 0px) and (max-width: 834px) {
    height: calc(100vh - 6.25rem);
  }

  @media all and (min-width: 0px) and (max-width: 834px) and (orientation: landscape) {
    height: calc(100vh - 3.75rem);
  }
`

const InnovatricsDocumentAutoCapture = (props: DocumentCameraProps) => {
  useEffect(() => {
    // 2. Init existed custom web-component
    const documentAutoCaptureHTMLElement = document.getElementById('x-dot-document-auto-capture')

    if (documentAutoCaptureHTMLElement) {
      // @ts-ignore
      documentAutoCaptureHTMLElement.cameraOptions = props
    }
  })

  // 1. Return empty custom web-component html TAG
  // @ts-ignore
  return <x-dot-document-auto-capture id='x-dot-document-auto-capture' />
}

interface DocumentAutoCaptureProps {
  onPhotoTaken: DocumentCameraProps['photoTakenCb']
  translations: Translations
}

export const DocumentAutoCapture = ({ onPhotoTaken, translations }: DocumentAutoCaptureProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    wrapperRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, [])

  return (
    <AutoCaptureWrapper ref={wrapperRef}>
      <InnovatricsDocumentAutoCapture
        samWasmUrl={`${window.location.origin}/web/public/sam.wasm`}
        imageType='png'
        cameraFacing='environment'
        photoTakenCb={onPhotoTaken}
        uiCustomisation={{
          instructions: translations.autoCapture.instructions,
          loading: { text: translations.autoCapture.loading }
        }}
      />
    </AutoCaptureWrapper>
  )
}
