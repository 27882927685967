import styled from 'styled-components'

import {
  radioButtonBackgroundActiveColor, radioButtonCircleActiveColor, radioButtonTextActiveColor
} from '../../../../../../theme'

export const Input = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  z-index: 5;

  &:checked + label {
    background-color: ${radioButtonBackgroundActiveColor};
    color: ${radioButtonTextActiveColor};
    border: 1px solid ${radioButtonBackgroundActiveColor};

    &:before {
      color: ${radioButtonCircleActiveColor};
      background-color: ${radioButtonCircleActiveColor};
      border-color: ${radioButtonCircleActiveColor};
    }

    &:after {
      background-color: ${radioButtonBackgroundActiveColor};
    }
  }
`
