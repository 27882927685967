import { DateTime } from 'luxon'
import React, { FC, useRef } from 'react'

import { Input } from '../../../input/root'
import { Field, FieldComponentProps } from '../../root/field'

const Component = Field<typeof Input, FieldComponentProps<string>>()
const components = { ...Component?.defaultProps?.components, InputComponent: Input }

export const FieldDateMobile: FC<FieldComponentProps<string>> = (props) => {
  const { field, onChange } = props
  const zone = useRef('utc')

  const toDateInputFormat = (value?: string) => {
    if (!value) {
      return undefined
    }

    const dateTime = DateTime.fromISO(value, { setZone: true })

    if (dateTime.isValid) {
      zone.current = dateTime.zoneName

      return dateTime.toISODate()
    }

    return undefined
  }

  const fromDateInputFormat = (value: string) => {
    const dateTime = DateTime.fromISO(value, { zone: zone.current })

    if (dateTime.isValid) {
      return dateTime.toFormat('yyyy-MM-dd\'T\'HH:mm:ss.SSSZZZ')
    }

    return null
  }

  const onChangeHandler = (value: string) => {
    const newValue = fromDateInputFormat(value)

    onChange && onChange(newValue || '')
  }

  return (
    <Component
      {...props}
      field={{ ...field, value: toDateInputFormat(field.value), type: 'date', required: true }}
      onChange={onChangeHandler}
      components={components}
    />
  )
}
