import React, { FC, useState } from 'react'

import { theme } from '../../../../theme'

import { IconWrapper } from './components/icon-wrapper'
import { LinkWrapper } from './components/link-wrapper'
import { TextWrapper } from './components/text-wrapper'

interface ButtonDownloadProps {
  name: string
  sourcePath?: string
  onClick?: () => void
  icon?: string
  alt?: string
  target?: string
  rel?: string
}

export const ButtonDownload: FC<ButtonDownloadProps> = (props) => {
  const { children, sourcePath, onClick, icon, alt, name, target, ...restProps } = props
  const [hover, setHover] = useState(false)

  const onMouseEnterHandler = () => setHover(true)
  const onMouseLeaveHandler = () => setHover(false)

  return (
    <LinkWrapper
      href={sourcePath}
      onClick={onClick}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      data-test-id={`buttonDownload-${name}`}
      target={target || '_self'}
      {...restProps}
    >
      <IconWrapper
        hover={hover}
      >
        <img
          src={icon || theme.icon.pdf}
          alt={alt || 'PDF'}
        />
      </IconWrapper>
      <TextWrapper
        hover={hover}
      >
        {children}
      </TextWrapper>
    </LinkWrapper>
  )
}
