import styled from 'styled-components'

import { listItemStyleBackgroundColor } from '../../../../theme'

export interface ListItemStyledProps {
  display?: string
}

export const ListItemStyled = styled.li<ListItemStyledProps>`
  padding: 0 0 3px 3rem;
  margin: 0;
  position: relative;
  display: ${({ display }) => display || 'list-item'};

  &:before {
    content: ' ';
    position: absolute;
    left: 0.8rem;
    top: 0.8rem;
    width: 0.6rem;
    height: 0.6rem;
    background-color: ${listItemStyleBackgroundColor};
  }
`
