import styled from 'styled-components'

export const PreviewStyled = styled.img`
  width: auto;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transition: opacity 150ms linear;
  transform: translate(-50%,-50%);
`
