import styled from 'styled-components'

import { identityPaperBorderColor } from '../../../theme'

export const PaperStyled = styled.a`
  position: relative;
  flex-grow: 1;
  margin: 0 1.5rem;
  border: 1px solid ${identityPaperBorderColor};
  padding: 0;
  text-align: center;
  min-height: 21rem;
  display: flex;
  justify-content: center;
  align-items: stretch;
  text-decoration: none;
  cursor: pointer;
`
