import styled from 'styled-components'

import {
  buttonSecondaryTextColor,
  buttonSecondaryBorderColor,
  buttonSecondaryBackgroundColor,
  buttonSecondaryTextActiveColor,
  buttonSecondaryBackgroundActiveColor
} from '../../../../../theme'
import { ButtonStyled } from '../../components/button-styled'

export const ButtonSecondaryStyled = styled(ButtonStyled)`
  border: 1px solid ${buttonSecondaryBorderColor};
  color: ${buttonSecondaryTextColor};
  border-radius: 0;
  background-color: ${buttonSecondaryBackgroundColor};
  transition: background-color 150ms linear, color 150ms linear, opacity 150ms linear,
    border-color 150ms linear;
  text-decoration: none;

  &:hover {
    background-color: ${buttonSecondaryBackgroundActiveColor};
    border: 1px solid ${buttonSecondaryBackgroundActiveColor};
    color: ${buttonSecondaryTextActiveColor};
  }
`
