import { DropzoneRootProps } from 'react-dropzone'
import styled from 'styled-components'
import { space } from 'styled-system'

import {
  dragAndDropSeparatorOnDragColor, dragAndDropSeparatorColor, dragAndDropBackgroundOnDragColor
} from '../../../../../theme'

export enum DragAndDropType {
  Webcam,
  Upload,
  Preview
}

export interface ContainerProps {
  type: DragAndDropType
  setType: (type: DragAndDropType) => void
}

const borderStyles = ({ type, isDragActive }: DropzoneRootProps) => {
  if (type === DragAndDropType.Preview) {
    return 'none'
  }

  if (isDragActive) {
    return '1px solid'
  }

  return '1px dashed'
}

export const Container = styled('div').attrs(({ type }: DropzoneRootProps) => ({
  paddingTop: [type === DragAndDropType.Webcam ? '0' : '56%']
}))<ContainerProps & DropzoneRootProps>`
  ${space};
  margin: 2rem 0 0;
  width: 100%;
  border: ${(props) => borderStyles(props)};
  border-color: ${({ isDragActive }) => (isDragActive ? dragAndDropSeparatorOnDragColor : dragAndDropSeparatorColor)};
  background-color: ${({ isDragActive }) => (isDragActive ? dragAndDropBackgroundOnDragColor : null)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  line-height: 2.5rem;
`
