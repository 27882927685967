import { MouseEvent } from 'react'
import styled from 'styled-components'

import {
  checkboxBackgroundActiveColor, checkboxBackgroundColor, checkboxBorderColor, inputLabelColor, inputTextErrorColor
} from '../../../../../../theme'

export interface LabelProps {
  noMargin?: boolean
  onClick?: (event: MouseEvent) => void
  showError: boolean
}

export const Label = styled.label<LabelProps>`
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: ${({ showError }) => (showError ? inputTextErrorColor : inputLabelColor)};
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: none;
  margin: ${({ noMargin }) => (noMargin ? '1.4em 0 0' : '3em 0 0')};
  line-height: 1.9rem;
  padding: 2px 0 2px 3rem;
`

export const CheckMark = styled.span`
  position: absolute;
  top: -3px;
  left: 0;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 0;
  box-shadow: none;

  &:before {
    height: 2rem;
    width: 2rem;
    content: ' ';
    position: absolute;
    left: 0;
    top: 0.5rem;
    border: 1px solid ${checkboxBorderColor};
    border-radius: 0;
    background-color: ${checkboxBackgroundColor};
  }

  &:after {
    display: none;
    position: absolute;
    left: 0;
    top: 0.6rem;
    content: "\\6c";
    font-family: 'jat';
    font-weight: normal;
    text-transform: none;
    font-size: 1.6rem;
    color: #fff;
    width: 2rem;
    line-height: 1.8rem;
    vertical-align: middle;
    text-align: center;
  }
`

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked {
    & + ${CheckMark}:before {
      background-color: ${checkboxBackgroundActiveColor};
      border-color: ${checkboxBackgroundActiveColor};
    }

    & ~ ${CheckMark}:after {
      display: block;
    }
  }
`
