import styled from 'styled-components'

export const BannerWrapper = styled.div<{ isWelcome: boolean }>`
  position: sticky;
  top: auto;
  left: 0;
  bottom: 1rem;
  right: 0;
  z-index: 69420;
  padding: 1.5rem;
  margin: 1rem;
  background: white;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);

  p,
  button,
  a {
    font-family: ${({ isWelcome }) => (isWelcome ? 'Geometria-Medium' : 'Geometria')};
  }
`
