import styled from 'styled-components'
import { space } from 'styled-system'

import { buttonGroupSeparatorColor } from '../../../../theme'

export const MainWrapper = styled.div.attrs({
  paddingTop: ['0.7rem', null, '2rem']
})`
  ${space};
  position: relative;
  margin-top: 3rem;

  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    width: calc(100%);
    border-top: 1px solid;
    border-color: ${buttonGroupSeparatorColor};
    height: 1px;
}
`
