import { notEmpty } from '@jtb-don-fe/utils'
import styled, { css } from 'styled-components'

import {
  inputBackgroundColor,
  inputBorderActiveColor, inputBorderColor, inputBorderErrorColor, selectBackgroundColor,
  selectNonSelectedBackgroundColor
} from '../../../../../../theme'

interface SelectControlStyledProps {
  isFocused: boolean
  disabled: boolean
  hasValue: boolean
  toggleable: boolean
  phoneSelect: boolean
  showError: boolean
  errors?: Array<{ code: string; message: string }>
  activeOuter?: boolean
}

type Height = Pick<SelectControlStyledProps, 'toggleable' | 'phoneSelect'>
type BackgroundColor = Pick<SelectControlStyledProps, 'hasValue' | 'phoneSelect' | 'activeOuter'>

const borderStyles = () => css`
  border-color: ${inputBorderColor};
  border-width: 1px;
  border-style: solid;

  &:focus {
    border-color: ${inputBorderActiveColor};
  }
`

const borderErrorStyles = () => css`
  border-color: ${inputBorderErrorColor};
  border-width: 2px;
  border-style: solid;
`

const height = ({ toggleable, phoneSelect }: Height) => {
  if (toggleable) {
    return '3.6rem'
  }

  if (phoneSelect) {
    return '5.4rem'
  }

  return '6rem'
}

const backgroundColor = ({ hasValue, phoneSelect, activeOuter }: BackgroundColor) => {
  if (phoneSelect) {
    return activeOuter ? selectBackgroundColor : inputBackgroundColor
  }

  return hasValue ? selectBackgroundColor : selectNonSelectedBackgroundColor
}

export const SelectControlStyled = styled.div<SelectControlStyledProps>`
  cursor: pointer;

  > div {
    height: ${height};
    ${({ phoneSelect }) => !phoneSelect && css<SelectControlStyledProps>`
      border-color: ${({ isFocused }) => (isFocused ? inputBorderActiveColor : inputBorderColor)};
      border-width: 1px;
      border-style: solid;
      ${({ errors, showError }) => (showError && notEmpty(errors) ? borderErrorStyles() : borderStyles())};
    `};
    opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
    background-color: ${backgroundColor};
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out;
  }
`
