import { FC } from 'react'
import styled from 'styled-components'

import { textColor } from '../../../../theme'
import { TextCss, TextType } from '../../root/text'

export const SubTitleStyled: FC<TextType> = styled.h2.attrs({
  'data-test-id': 'sub-title'
})`
  ${TextCss};
  color: ${textColor};
  font-size: 2rem;
  font-weight: 500;
  line-height: 3.36rem;
`
