import React, { ChangeEvent } from 'react'

import { ToggleWrapper } from './components/toggle-styled'
import { InputToggleProps } from './types'

export const InputToggle = ({
  disabled,
  name,
  checked,
  onChange,
  onBlur,
  label,
  labelBefore
}: InputToggleProps): JSX.Element => {
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => !disabled && onChange?.(event)

  return (
    <ToggleWrapper aria-checked={checked} checked={checked} disabled={disabled} labelBefore={labelBefore}>
      <input
        checked={checked}
        disabled={disabled}
        id={name}
        name={name}
        type='checkbox'
        onBlur={onBlur}
        onChange={changeHandler}
      />
      <label htmlFor={name}>{label}</label>
    </ToggleWrapper>
  )
}
