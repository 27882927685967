import React, { FC, memo } from 'react'

import { InputBase, InputBaseProps } from '../../base/input-base'

import { InputStyled } from './input-styled'

const Input = InputBase<{}>(InputStyled)

export const InputView: FC<InputBaseProps> = memo((props) => (
  <Input
    {...props}
  />
))
