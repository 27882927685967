import DOMPurify from 'dompurify'
import { decode } from 'he'
import React, { DOMAttributes, FC, ReactNode, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { space, color, typography, compose, SpaceProps, ColorProps, TypographyProps } from 'styled-system'

export const TextCss = css(compose(space, color, typography))

export type TextType =
  SpaceProps &
  ColorProps &
  TypographyProps & {
  as?: ReactNode
}

type TextBase =
  TextType &
  Pick<DOMAttributes<{}>, 'dangerouslySetInnerHTML'>

export const TextBase: FC<TextBase> = styled.span`
  ${TextCss}
`

export const Text: FC<TextType> = ({ children, ...props }) => {
  useEffect(() => {
    const allowedURISchemes = ['http', 'https', 'mailto', 'tel']

    const regex = RegExp(`^(${allowedURISchemes.join('|')}):`, 'gim')

    DOMPurify.addHook('afterSanitizeAttributes', (node) => {
      const anchor = document.createElement('a')

      if (node.hasAttribute('href')) {
        anchor.href = node.getAttribute('href') || ''

        if (anchor.protocol && !anchor.protocol.match(regex)) {
          node.removeAttribute('href')
        }
      }
    })
  }, [])

  return (typeof children === 'string'
    ? (
      <TextBase
        {...props}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(decode(children), {
            ALLOWED_TAGS: ['a', 'strong', 'br', 'ul', 'ol', 'li', 'span'],
            ALLOWED_ATTR: ['href', 'target', 'class', 'src', 'style']
          })
        }}
      />
    )
    : (
      <TextBase
        {...props}
      >
        {children}
      </TextBase>
    )
  )
}
