import styled from 'styled-components'

import { fontMedium, identityPaperTextColor } from '../../../theme'

export const LabelStyled = styled.div`
  margin: 1rem 0;
  color: ${identityPaperTextColor};
  font-size: 1.5rem;
  ${fontMedium};
`
