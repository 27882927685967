import React, { FC } from 'react'

import { ButtonLinkStyled } from './components/button-link-styled'

interface ButtonLinkProps {
  name: string
  onClick: () => void
}

export const ButtonLink: FC<ButtonLinkProps> = (props) => (
  <ButtonLinkStyled
    data-test-id={`buttonLink-${props.name}`}
    type='button'
  />
)
