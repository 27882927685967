import styled from 'styled-components'

export const InputDateWrapper = styled.div`
  position: relative;

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    font-size: 1.2rem;
    font-family: Geometria;
    font-weight: 500;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 2.5rem;
  }
`
