import Cookies from 'js-cookie'
import { isBrowser } from 'react-device-detect'

declare global {
  interface Window {
    dataLayer: {
      push: (data: object) => void
    }
  }
}

export const pushPageVisited = (pageTitle: string, sessionIdInitial?: string) => window?.dataLayer?.push({
  event: 'pageview',
  pageUrl: window.location.href,
  pageTitle,
  deviceType: isBrowser ? 'desktop' : 'mobile',
  sessionId: Cookies.get('onboarding_workflow_instance_id'),
  sessionIdInitial
})

export const pushButtonClicked = (buttonName: string) => window?.dataLayer?.push({ event: 'button', buttonName })
