import React, { FC, memo } from 'react'

import { Grid } from '../../../../../layout/grid'

interface ContentWrapperProps {
  singleLine?: boolean
}

const ContentWrapperBase: FC<ContentWrapperProps> = ({ children, singleLine }) => {
  const gridTemplateColumns = singleLine ? ['auto', 'repeat(auto-fit, minmax(10rem, 1fr))'] : ['auto', '1fr 1fr']

  return (
    <Grid
      gridTemplateColumns={gridTemplateColumns}
      gridGap='1.5rem'
    >
      {children}
    </Grid>
  )
}

export const ContentWrapper = memo(ContentWrapperBase)
