import styled from 'styled-components'

export const InfoStepsItemImgStyled = styled.div`
  width: 100%;
  height: 8rem;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
  }
`
