import React from 'react'

import { InfoStepsItemTxtStyled } from './components/info-steps-item-txt-styled'

export interface InfoStepsItemTxtProps {
  text: string
}

export const InfoStepsItemTxt = ({ text }: InfoStepsItemTxtProps) => (
  <InfoStepsItemTxtStyled>{text}</InfoStepsItemTxtStyled>
)
