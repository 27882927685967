import styled from 'styled-components'

import { Flex } from '../../layout'
import { theme } from '../../../theme'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const StyledModal = styled.div<any>`
  top: 50%;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  max-width: 850px;
  max-height: 100vh;
  overflow-x: hidden;
  z-index: 69421;

  p,
  button,
  a,
  li,
  span,
  tr {
    font-family: ${({ isWelcome }) => (isWelcome ? 'Geometria-Medium' : 'Geometria')};
  }
`

export const StyledOverlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3500;
  opacity: 0;
  transition: opacity 250ms ease-in-out;
  background-color: rgba(0, 0, 0, 0.7);

  &[class*='--after-open'] {
    opacity: 1;
  }

  &[class*='--before-close'] {
    opacity: 0;
  }
`

export const Header = styled(Flex).attrs({ px: 3 })`
  margin-left: auto;
  margin-right: auto;
  flex-flow: row wrap;
  display: flex;
  border-bottom: 1px solid #ececec;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 2;
`

export const Footer = styled(Flex).attrs({ p: 3, flexDirection: ['column', 'row'] })`
  border-top: 1px solid #ececec;
  justify-content: space-between;
`

export const Main = styled(Flex).attrs({ px: 3 })`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  max-height: 100vh;
  max-width: 100%;
  overflow-x: hidden;
`

export const UnorderedList = styled.ul`
  padding: 1rem 0 0 0;
  margin: 0;
`

export const ListItem = styled.li<{ active?: boolean }>`
  font-weight: 500;
  list-style: none;
  padding: 1rem 0 1rem 0;
  color: ${({ active }) => (active ? theme.color.primary : theme.color.text)};

  &:hover,
  &:focus {
    color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
  }
`
