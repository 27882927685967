import styled from 'styled-components'

export const LoaderStyled = styled.div`
  width: 12.4rem;
  height: 12.4rem;
  display: inline-block;
  overflow: hidden;
  background: 0 0;
  margin: 0 auto;
`
