import React from 'react'
import { components, InputProps } from 'react-select'

export const SelectInputNumbers = (props: InputProps) => {
  const inputProps = { ...props, type: 'tel' }

  return (
    <components.Input
      {...inputProps}
    />
  )
}
