import styled from 'styled-components'

import { buttonDownloadBackgroundColor } from '../../../../../theme'

export const LinkWrapper = styled.a`
  display: flex;
  width: 100%;
  background-color: ${buttonDownloadBackgroundColor};
  min-height: 9rem;
  text-decoration: none;
  margin-top: 3rem;
  cursor: pointer;
`
