import i18next from 'i18next'
import { mergeDeepLeft } from 'ramda'

export const getTranslations = (area: string, params: object = {}) => () => {
  const t = i18next.getFixedT(i18next.language)

  const areaTranslations = area ? t(area, params) : {}
  const commonTranslations = t('common', {})

  return { ...mergeDeepLeft(areaTranslations, commonTranslations) }
}
