import React, { FC } from 'react'
import { components, OptionProps } from 'react-select'

import { OptionType } from '../../select'

import { SelectOptionStyled } from './select-option-styled'

export const SelectOption: FC<OptionProps<OptionType>> = (props) => (
  <SelectOptionStyled
    toggleable={props.selectProps.toggleable}
    isSelected={props.isSelected}
    data-test-id={`select-${props.data.value}`}
  >
    <components.Option
      {...props}
    />
  </SelectOptionStyled>
)
