import { notEmpty, pushFormAction } from '@jtb-don-fe/utils'
import { init } from 'ramda'
import React, { CSSProperties, FC, useState } from 'react'
import { isMobile } from 'react-device-detect'
import ReactSelect, { Props, CommonProps } from 'react-select'

import { colorTheme } from '../../../../theme'
import { scrollToId } from '../../../../utils/window/scroll/scroll-to-id'
import { Error } from '../../input/base/input-base-error-styled'
import { Wrapper } from '../../input/base/input-wrapper-styled'

import { SelectControl } from './components/select-control/select-control'
import { SelectInputNumbers } from './components/select-input-numbers/select-input-numbers'
import { SelectInput } from './components/select-input/select-input'
import { SelectOption } from './components/select-option/select-option'
import { SelectPlaceholder } from './components/select-placeholder/select-placeholder'
import { SelectValueContainer } from './components/select-value-container/select-value-container'

export type OptionType = {
  label: string
  value: string
}

export type SelectOptions = Array<OptionType>

export interface SelectProps {
  options: Array<{
    value: string
    label: string
  }>
  name: string
  value: string
  onChange: (value: string) => void
  disabled?: boolean
  errors?: Array<{ code: string; message: string }>
  dirty?: boolean
  updating?: boolean
  focused?: boolean
  onBlur?: () => void
  onFocus?: () => void
  autoFocus?: boolean
  defaultMenuIsOpen?: boolean
  toggleable?: boolean
  isSearchable?: boolean
  activeOuter?: boolean
  phoneSelect?: boolean
  inputOnlyNumbers?: boolean
  maxLength?: number
}

export const Select: FC<SelectProps> = (props) => {
  const {
    options,
    disabled,
    name,
    value,
    onChange,
    isSearchable = false,
    errors = [],
    updating,
    dirty,
    onBlur,
    onFocus,
    activeOuter,
    inputOnlyNumbers
  } = props

  const [active, setActive] = useState(false)
  const id = `select-${name}`

  const styles = {
    control: () => ({}),
    dropdownIndicator: (provided: CSSProperties, state: CommonProps<OptionType>) => ({
      ...provided,
      color: colorTheme.primary,
      height: '100%',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      right: '0.8rem',
      top: '0',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null
    }),
    menu: (provided: CSSProperties) => ({
      ...provided,
      marginTop: '2px',
      marginBottom: '4px',
      borderRadius: 0,
      padding: '.5rem 0',
      zIndex: 10
    }),
    clearIndicator: (base: CSSProperties) => ({
      ...base,
      position: 'absolute',
      top: 0,
      right: '2.5rem',
      bottom: 0,
      alignItems: 'center'
    })
  }

  const components = {
    Control: SelectControl,
    Input: inputOnlyNumbers ? SelectInputNumbers : SelectInput,
    Option: SelectOption,
    Placeholder: SelectPlaceholder,
    ValueContainer: SelectValueContainer
  }

  const onFocusHandler = () => {
    onFocus && onFocus()
    setActive(true)
    isMobile && scrollToId(id)
    pushFormAction('form field entry', name, dirty ? errors?.length : 0)
  }

  const onBlurHandler = () => {
    onBlur && onBlur()
    setActive(false)
    pushFormAction('form field exit', name, errors?.length)
  }

  const onChangeHandler = (option: OptionType) => onChange && onChange(option?.value)

  const showError = notEmpty(errors) && (updating || dirty)

  const isActive = activeOuter || active

  const onInputChangeHandler = (newValue: string) => {
    const value = inputOnlyNumbers ? newValue.replace(/\D/g, '') : newValue

    if (props.maxLength && value.length > props.maxLength) {
      return init(value)
    }

    return value
  }

  return (
    <Wrapper data-test-id={id} data-error={!!showError}>
      <ReactSelect
        placeholder={null}
        {...props}
        options={options}
        isSearchable={isSearchable}
        activeOuter={activeOuter}
        isDisabled={disabled}
        showError={showError}
        errors={errors}
        // @ts-ignore
        styles={styles}
        onChange={onChangeHandler as Props['onChange']}
        onBlur={onBlurHandler}
        onFocus={onFocusHandler}
        value={options?.find((option) => option.value === value)}
        components={components}
        onInputChange={onInputChangeHandler}
        id={id}
      />
      {showError && notEmpty(errors) && !isActive && <Error inputValue={value}>{errors[0].message}</Error>}
    </Wrapper>
  )
}
