import styled from 'styled-components'

import { textColor, fontBold } from '../../../../../../theme'

export const InfoStepsItemTxtStyled = styled.div`
  width: 100%;
  color: ${textColor};
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.2em;
  padding: 1.5rem 0;
  ${fontBold}
`
