import styled from 'styled-components'

export const PhoneWrapper = styled.div`
  position: relative;

  > div:nth-child(2) {
    input {
      padding-left: 7rem;
    }
  }
`
