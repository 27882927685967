import styled from 'styled-components'

export const PdfPreviewWrapper = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;

  .react-pdf__Document {
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 8px;
    max-width: 100%;
    position: relative;
    background-color: white;
    overflow-y: auto;
  }
`

export const LoaderWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const ReactPdfControls = styled.div`
  position: absolute;
  bottom: 5%;
  left: 50%;
  background: white;
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  box-shadow: 0 0 40px 0 rgba(16, 36, 94, 0.2);
  border-radius: 4px;

  span {
    font: inherit;
    font-size: 0.8em;
    padding: 0 0.5em;
  }

  button {
    width: 44px;
    height: 44px;
    background: white;
    border: 0;
    font: inherit;
    font-size: 0.8em;
    border-radius: 4px;

    &:hover {
      cursor: pointer;
    }

    &:enabled:hover,
    &:enabled:focus {
       background-color: #e6e6e6;
    }

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`
