import { Translations } from '@jtb-don-fe/types'
import React, { FC } from 'react'
import { isMobile, isBrowser } from 'react-device-detect'

import { ButtonGroup } from '../../../../layout/button-group'
import { ButtonSecondary } from '../../../button/secondary'
import { DragAndDropType } from '../../root'
import { ActionHandlers } from '../drag-and-drop-layout'

interface UploadButtonsProps extends Pick<ActionHandlers, 'onMobileStorage'> {
  onUploadFile: () => void
  setType: (value: DragAndDropType) => void
  toPhoneUpload: () => void
  translations: Translations
}

export const UploadButtons: FC<UploadButtonsProps> = ({
  onUploadFile,
  setType,
  translations,
  onMobileStorage,
  toPhoneUpload
}) => {
  const switchOnWebcam = () => setType(DragAndDropType.Webcam)

  return (
    <ButtonGroup>
      {isMobile && (
        <>
          <ButtonSecondary onClick={onMobileStorage} name='byStorage'>
            {translations.button.upload.mobile.byStorage}
          </ButtonSecondary>
          <ButtonSecondary onClick={switchOnWebcam} name='byCamera'>
            {translations.button.upload.mobile.byCamera}
          </ButtonSecondary>
        </>
      )}

      {isBrowser && (
        <>
          <ButtonSecondary onClick={onUploadFile} name='byStorage'>
            {translations.button.upload.browser.byStorage}
          </ButtonSecondary>
          <ButtonSecondary onClick={switchOnWebcam} name='byWebcam'>
            {translations.button.upload.browser.byWebcam}
          </ButtonSecondary>
          <ButtonSecondary onClick={toPhoneUpload} name='byPhone'>
            {translations.button.upload.browser.byPhone}
          </ButtonSecondary>
        </>
      )}
    </ButtonGroup>
  )
}
