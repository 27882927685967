import { Translations } from '@jtb-don-fe/types'
import { notEmpty, pushFormAction } from '@jtb-don-fe/utils'
import { AnimatePresence } from 'framer-motion'
import { path } from 'ramda'
import React, { Children, FC, ReactElement, ReactNode, useEffect, useState } from 'react'

import { FieldLabel } from '../../../field/root/components/field-label-styled'
import { FieldProps } from '../../../field/root/field'
import { RadioButtonProps } from '../root/components/radio-button-view'

import { ContentMotion } from './components/content-motion-styled'
import { ContentWrapper } from './components/content-wrapper'
import { ExpandableWrapper } from './components/expandable-wrapper'
import { LabelWrapper } from './components/label-wrapper'

interface RadioFormProps {
  field: FieldProps<string>
  children: ReactElement<RadioButtonProps>[]
  translations: Translations
  onChange: (value: string | number) => void
  checkedValue?: string | number
  expandableField?: ReactNode
  components?: {
    LabelWrapperComponent?: Function
    ContentWrapperComponent?: Function
    ExpandableWrapperComponent?: Function
  }
}

export const RadioButtonGroup: FC<RadioFormProps> = (
  { field, components, translations, children, onChange, expandableField }
) => {
  const [expanded, setExpanded] = useState(false)
  const [anyChildChecked, setAnyChildChecked] = useState(false)
  const { name, value: checkedValue, errors, dirty, updating } = field
  const label: string | undefined = path([name, 'label'], translations)
  const LabelWrapperComponent = components?.LabelWrapperComponent || LabelWrapper
  const ContentWrapperComponent = components?.ContentWrapperComponent || ContentWrapper
  const ExpandableWrapperComponent = components?.ExpandableWrapperComponent || ExpandableWrapper
  const showError = notEmpty(errors) && (updating || dirty)

  useEffect(
    () => {
      const checkedChildren = children.find((child) => child.props.value === checkedValue)
      setAnyChildChecked(!!checkedChildren)
    },
    [children, checkedValue]
  )

  return (
    <>
      {notEmpty(label) && (
        <LabelWrapperComponent>
          <FieldLabel
            showError={!!showError}
            data-error={!!showError}
            data-test-id={`radioButtonGroupLabel-${name}`}
          >
            {label}
          </FieldLabel>
        </LabelWrapperComponent>
      )}

      <ContentWrapperComponent
        singleLine={children.length <= 3}
        data-test-id={`radioButtonGroup-${name}`}
      >
        {Children.map(children, (child, index) => {
          const { value, ...rest } = child.props
          const checked = checkedValue === value
          const onPress = () => {
            const lastItem = index === children.length - 1

            onChange(value)
            pushFormAction('select', value.toString(), 0)

            if (lastItem) {
              expandableField && setExpanded(true)
            } else {
              setExpanded(false)
            }
          }

          return { ...child, props: { ...rest, name, value, checked, anySiblingChecked: anyChildChecked, onPress } }
        })}
      </ContentWrapperComponent>

      {expandableField && (
        <ExpandableWrapperComponent>
          <AnimatePresence
            initial={false}
          >
            {expanded && (
              <ContentMotion>
                {expandableField}
              </ContentMotion>
            )}
          </AnimatePresence>
        </ExpandableWrapperComponent>
      )}
    </>
  )
}
