import 'core-js/stable'
import 'regenerator-runtime/runtime.js'
import './static-files/unsupported-browser/browser-compatibility'
import './logic/core/translations/i18n'
import React from 'react'
import ReactDOM from 'react-dom'
import { GlobalStyles, theme as defaultTheme } from '@jtb-don-fe/ui'
import { createTheme } from '@jtb-don-fe/config'
import { ThemeProvider } from 'styled-components'

import { fetchConfiguration } from './logic/core/configuration/fetch-configuration'
import { fetchLocalizations } from './logic/core/translations/fetch-localizations'
import { internetExplorerTextEncoder } from './logic/utils/internet-explorer'
import { AppContainer } from './modules/app/app.container'

internetExplorerTextEncoder()

const NODE = document.getElementById('app')
const theme = createTheme(defaultTheme)
const render = (translations, configuration) => {
  const Root = (
    <ThemeProvider theme={theme}>
      <AppContainer translations={translations} configuration={configuration} />
      <GlobalStyles />
    </ThemeProvider>
  )

  ReactDOM.render(Root, NODE)
}

fetchLocalizations().then((translations) =>
  fetchConfiguration().then((configuration) => render(translations, configuration))
)

if (module.hot) {
  module.hot.accept('./')
}
