import React, { FC } from 'react'

import { InputDate } from '../../../input/date'
import { Field, FieldComponentProps } from '../../root/field'

export interface FieldDateDesktop extends FieldComponentProps<string> {
  openCalendar: string
}

const Component = Field<typeof InputDate, FieldDateDesktop>()
const components = { ...Component?.defaultProps?.components, InputComponent: InputDate }

export const FieldDateDesktop: FC<FieldDateDesktop> = (props) => (
  <Component
    {...props}
    components={components}
  />
)
