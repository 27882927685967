import { AnimatePresence } from 'framer-motion'
import React, { FC, ReactNode } from 'react'

import { ContentMotion } from './components/content-motion'

interface CollapseProps {
  HeaderComponent: ReactNode
  collapsed?: boolean
}

export const Collapse: FC<CollapseProps> = ({ HeaderComponent, collapsed, children }) => (
  <>
    {HeaderComponent}

    <AnimatePresence
      initial={false}
    >
      {collapsed && (
        <ContentMotion>
          {children}
        </ContentMotion>
      )}
    </AnimatePresence>
  </>
)
