import { Translations } from '@jtb-don-fe/types'
import React, { FC, useCallback, useState } from 'react'

import { ButtonLink } from '../../button/link/root'
import { Select } from '../root'
import { SelectProps } from '../root/select'

import { Value } from './components/value-styled'
import { Wrapper } from './components/wrapper-styled'

interface ToggleableSelectProps extends SelectProps {
  clickableText?: string
  selectedValue?: string
  translations: Translations
}

export const ToggleableSelect: FC<ToggleableSelectProps> = (props) => {
  const { name, value, onChange, options, translations, ...restProps } = props
  const [active, setActive] = useState(false)

  const onClickHandler = useCallback(() => setActive(!active), [active])

  const onChangeHandler = useCallback((value: string) => {
    setActive(false)

    if (onChange) {
      onChange(value)
    }
  }, [onChange])

  const onBlurHandler = () => {
    setActive(false)
  }

  const clickableText = translations?.change
  const selectedValue = translations?.component?.select?.[name]?.option?.[value.toUpperCase()]

  return (
    <>
      {!active && (
        <>
          <Value>&nbsp;{`${selectedValue} `}</Value>
          <ButtonLink
            name={name}
            onClick={onClickHandler}
          >
            {clickableText}
          </ButtonLink>
        </>
      )}

      {active && (
        <Wrapper>
          <Select
            {...restProps}
            autoFocus
            defaultMenuIsOpen
            toggleable
            value={value}
            name={name}
            options={options}
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
          />
        </Wrapper>
      )}
    </>
  )
}
