/* eslint-disable max-len */
import * as React from 'react'

export const InfoIcon = () => (
  <svg width='25' height='25' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.99982 6.66671C9.88482 6.66671 9.79149 6.76004 9.79232 6.87504C9.79232 6.99004 9.88565 7.08337 10.0007 7.08337C10.1157 7.08337 10.209 6.99004 10.209 6.87504C10.209 6.76004 10.1157 6.66671 9.99982 6.66671'
      stroke='#081E1D'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 17.5V17.5C5.8575 17.5 2.5 14.1425 2.5 10V10C2.5 5.8575 5.8575 2.5 10 2.5V2.5C14.1425 2.5 17.5 5.8575 17.5 10V10C17.5 14.1425 14.1425 17.5 10 17.5Z'
      stroke='#081E1D'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M10.0007 10V14.1667' stroke='#081E1D' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
