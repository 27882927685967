import React, { FC } from 'react'

import { Input } from './radio-button-input-styled'
import { Label } from './radio-button-label-styled'
import { Wrapper } from './radio-button-wrapper-styled'

export interface RadioButtonProps {
  name: string
  value: string | number
  label?: string
  checked?: boolean
  anySiblingChecked?: boolean
  onPress?: () => void
}

export const RadioButtonView: FC<RadioButtonProps> = (
  { label, name, value, checked = false, anySiblingChecked = false, onPress }
) => (
  <Wrapper>
    <Input
      onClick={onPress}
      name={name}
      type='radio'
      id={`radioButton-${value}`}
      data-test-id={`radioButton-${value}`}
      data-error={checked}
      value={value}
      checked={checked}
    />
    <Label
      anySiblingChecked={anySiblingChecked}
      htmlFor={`radioButton-${value}`}
    >
      {label}
    </Label>
  </Wrapper>
)
