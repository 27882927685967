import { FC } from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'

import { fontMedium, inputLabelColor, inputTextErrorColor } from '../../../../../theme'

export interface FieldLabelProps {
  showError?: boolean
  htmlFor?: string
}

export const FieldLabel: FC<FieldLabelProps> = styled.label.attrs({
  margin: ['1.5rem 0 1rem', null, '2.5rem 0 1rem']
})<FieldLabelProps>`
 ${space};
  display: block;
  color: ${({ showError }) => (showError ? inputTextErrorColor : inputLabelColor)};
  line-height: 1em;
  ${fontMedium}
`
