import { OptionProps } from 'react-select'
import styled from 'styled-components'

import {
  fontMedium, selectOptionBackgroundActiveColor, selectOptionBackgroundColor,
  selectOptionBackgroundHoverColor, selectTextActiveColor, selectTextColor
} from '../../../../../../theme'
import { OptionType } from '../../select'

interface SelectOptionStyledProps {
  isSelected: OptionProps<OptionType>['isSelected']
  toggleable: boolean
}

const backgroundColor = ({ isSelected }: SelectOptionStyledProps) => (isSelected
  ? selectOptionBackgroundActiveColor
  : selectOptionBackgroundColor
)

const backgroundColorHover = ({ isSelected }: SelectOptionStyledProps) => (isSelected
  ? selectOptionBackgroundActiveColor
  : selectOptionBackgroundHoverColor
)

const color = ({ isSelected }: SelectOptionStyledProps) => (isSelected ? selectTextActiveColor : selectTextColor)

export const SelectOptionStyled = styled.div<SelectOptionStyledProps>`
  > div {
    cursor: pointer;
    background-color: ${backgroundColor};
    color: ${color};
    font-size: 1.5rem;
    padding: ${({ toggleable }) => (toggleable ? '0.4rem 1.2rem' : '.8rem 1.2rem')};
    ${fontMedium};

    &:hover {
      background-color: ${backgroundColorHover};
    }
  }
`
