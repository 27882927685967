/* eslint-disable */

(function (fallback) {
  var trap

  fallback = fallback || function () {}

  // function to trap most of the window.console functions from the FireBug Console API.
  trap = function () {
    var args, message

    // create an Array from the arguments Object
    args = Array.prototype.slice.call(arguments)

    // window.console.raw captures the raw args, without converting toString
    window.console.raw.push(args)

    message = args.join(' ')

    window.console.messages.push(message)

    fallback(message)
  }

  // redefine window.console
  if (typeof window.console === 'undefined') {
    window.console = {
      messages: [],
      raw: [],
      dump: function () {
        return window.console.messages.join('\n')
      },
      clear: function () {
        window.console.messages.length = 0
        window.console.raw.length = 0
      },
      log: trap,
      debug: trap,
      info: trap,
      warn: trap,
      error: trap,
      assert: trap,
      dir: trap,
      dirxml: trap,
      trace: trap,
      group: trap,
      groupCollapsed: trap,
      groupEnd: trap,
      time: trap,
      timeEnd: trap,
      timeStamp: trap,
      profile: trap,
      profileEnd: trap,
      count: trap,
      exception: trap,
      table: trap
    }
  }
})(null) // to define a fallback function, replace null with the name of the function (ex: alert)

window.addEventListener
  ? window.addEventListener('load', init, false)
  : window.attachEvent && window.attachEvent('onload', init)

function init() {
  var supportedBrowsers,
    unsupportedBrowsers,
    unsupportedBrowserPage,
    setLocation,
    getBrowser,
    isUnsupportedBrowser,
    setTextContent,
    result,
    resultId,
    resultVersion,
    versionValue

  supportedBrowsers = {
    msie: 9999, // IE, Trident (IE 11)
    // For each IE version since at least IE 8, the Trident version has been the IE version - 4. So IE 8 had Trident/4.0.
    edge: 16,
    firefox: 52,
    chrome: 57,
    safari: 10.2
  }

  unsupportedBrowsers = ['SznProhlizec', 'SamsungBrowser']

  unsupportedBrowserPage = function () {
    var path =
      String(window.location.pathname).indexOf('/unsupported-browser/index.html') === -1
        ? window.location.pathname
        : '/'
    return String(path + '/unsupported-browser/index.html').replace(/\/\/+/g, '/')
  }

  setLocation = function (href) {
    window.location.href = href
  }

  setTextContent = function (id, text) {
    var element = window.document.getElementById(id)

    if (element) {
      element[window.document.all && !window.document.addEventListener ? 'innerText' : 'textContent'] = text
    }
  }

  isUnsupportedBrowser = function () {
    return unsupportedBrowsers.some((browser) => window.navigator.userAgent.includes(browser))
  }

  getBrowser = function () {
    var ua, tem, M

    ua = window.navigator.userAgent
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []

    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || []
      return ['msie', tem[1] || '']
    }

    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/)

      if (tem != null) {
        return tem.slice(1).join(',').replace('OPR', 'Opera').split(',')
      }
    }

    M = M[2] ? [M[1], M[2]] : [window.navigator.appName, window.navigator.appVersion, '-?']

    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1])
    }

    return M
  }

  result = getBrowser()

  if (!result || result.length !== 2) {
    fail()
  }

  resultId = getBrowser()[0]
  resultVersion = getBrowser()[1]
  versionValue = supportedBrowsers[result[0].toLowerCase()]

  try {
    window.console.log('Browser, resultId ' + resultId)
    window.console.log('Browser, resultVersion ' + resultVersion)
    window.console.log('Browser, versionValue ' + versionValue)
  } catch (error) {
    // do nothing
  }

  if (!versionValue || isNaN(versionValue) || resultVersion < versionValue || isUnsupportedBrowser()) {
    var xmlHttpRequest = new XMLHttpRequest()
    xmlHttpRequest.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        var data = JSON.parse(this.responseText)
        var translations = data.cs

        setTextContent('title', translations['unsupportedBrowser.title'])
        setTextContent('text', translations['unsupportedBrowser.text'])
        setTextContent('end', translations['unsupportedBrowser.end'])
        setTextContent('bank', translations['unsupportedBrowser.bank'])
      }
    }
    xmlHttpRequest.open('GET', `${window.location.origin}/api/localization`, true)
    xmlHttpRequest.send()

    fail()
  }

  function fail() {
    var ieVersion

    if (window.location.href.indexOf(unsupportedBrowserPage()) === -1) {
      return setLocation(unsupportedBrowserPage())
    }

    if (resultId.toLowerCase() === 'msie') {
      ieVersion = Math.floor(resultVersion)

      if (ieVersion >= 6 && ieVersion <= 9) {
        window.document.body.className += ' ie' + Math.floor(ieVersion)
      }
    }
  }

  window.nsbipProceed = function () {
    var el, result, resultId, resultVersion, ieVersion

    result = getBrowser()

    if (!result || result.length !== 2) {
      return false
    }

    resultId = getBrowser()[0]
    resultVersion = getBrowser()[1]

    if (resultId.toLowerCase() === 'msie') {
      ieVersion = Math.floor(resultVersion)

      if (ieVersion >= 6 && ieVersion <= 9) {
        if (window.location.href.indexOf(unsupportedBrowserPage()) > -1) {
          return setLocation('')
        }
      }
    }
  }
}
