import { Translations } from '@jtb-don-fe/types'
import React, { FC, useState } from 'react'

import { DragAndDrop, DragAndDropType } from '../root'
import { RenderLayoutProps } from '../root/drag-and-drop-component'

import { Placeholder } from './components/placeholder'
import { PreviewButtons } from './components/preview-buttons'
import { UploadButtons } from './components/upload-buttons'
import { WebcamButtons } from './components/webcam-buttons'

interface DragAndDropLayoutProps {
  allowedMimeTypes: Array<string>
  allowedFileSize: number
  validateFile: Function
  placeholder?: string
  value: File
  onUpload: (file?: File) => void
  onConfirm: () => void
  toPhoneUpload: () => void
  translations: Translations
}

export interface ActionHandlers {
  onUploadFile: () => void
  onSubmitPreview: () => void
  onMobileReset: () => void
  onMobileStorage: () => void
}

export const DragAndDropLayout: FC<DragAndDropLayoutProps> = (props) => {
  const {
    placeholder,
    value,
    onUpload,
    onConfirm,
    toPhoneUpload,
    translations,
    allowedMimeTypes,
    allowedFileSize,
    validateFile
  } = props
  const [type, setType] = useState(DragAndDropType.Upload)

  const getButtons = ({ onUploadFile, onSubmitPreview, onMobileStorage, onMobileReset }: ActionHandlers) => {
    switch (type) {
      case DragAndDropType.Upload:
        return (
          <UploadButtons
            translations={translations}
            onUploadFile={onUploadFile}
            setType={setType}
            onMobileStorage={onMobileStorage}
            toPhoneUpload={toPhoneUpload}
          />
        )
      case DragAndDropType.Webcam:
        return <WebcamButtons translations={translations} setType={setType} setValue={onUpload} />
      case DragAndDropType.Preview:
        return (
          <PreviewButtons
            translations={translations}
            setType={setType}
            setValue={onUpload}
            onClick={onSubmitPreview}
            onMobileReset={onMobileReset}
          />
        )
      default:
        return null
    }
  }

  const placeholderComponent = <Placeholder variableText={placeholder} />

  const RenderLayout = ({ DragAndDropComponent, actionHandlers }: RenderLayoutProps) => (
    <>
      {DragAndDropComponent}
      {getButtons(actionHandlers)}
    </>
  )

  return (
    <DragAndDrop
      allowedMimeTypes={allowedMimeTypes}
      allowedFileSize={allowedFileSize}
      validateFile={validateFile}
      type={type}
      setType={setType}
      placeholder={placeholderComponent}
      translations={translations}
      value={value}
      onUpload={onUpload}
      onConfirm={onConfirm}
      renderLayout={RenderLayout}
    />
  )
}
