import { notEmpty, translateErrors } from '@jtb-don-fe/utils'
import { FieldProps } from 'core-ui'
import { omit, path } from 'ramda'
import React, { FC } from 'react'

import { FieldComponentProps } from '../field'

import { FieldLabel } from './field-label-styled'

export type FieldViewProps = FieldComponentProps & FieldProps

const omitInternalProps = (props: object) => omit([
  'renderErrors', 'renderMessages', 'renderWarnings', 'passLabel'
], props)

export const FieldView: FC<FieldViewProps> = ({ components, translations, field, ...props }) => {
  const InputComponent = components?.InputComponent
  const FieldLabelComponent = components?.FieldLabelComponent
  const { name, errors } = field
  const label: string | undefined = path([name, 'label'], translations)
  const placeholder: string | undefined = path([name, 'placeholder'], translations)

  const restProps = omitInternalProps(props)

  const Label = FieldLabelComponent
    ? <FieldLabelComponent />
    : (
      <FieldLabel
        data-test-id={`fieldLabel-${name}`}
        htmlFor={name}
      >
        {label}
      </FieldLabel>
    )

  return (
    <div
      style={{
        width: '100%'
      }}
    >
      {notEmpty(label) && Label}

      <InputComponent
        {...restProps}
        {...field}
        id={name}
        data-test-id={`field-${name}`}
        translations={translations}
        placeholder={placeholder}
        label={label}
        errors={translateErrors(translations, errors, name)}
      />
    </div>
  )
}
