import React from 'react'

import { onClickButton } from '../../../../../utils'
import { ButtonProps } from '../../components/button-styled'

import { ButtonLinkTransparentStyled } from './components/button-link-transparent-styled'

export const ButtonLinkTransparent: React.FC<ButtonProps> = ({ children, type, onClick, formValid, ...props }) => (
  <ButtonLinkTransparentStyled
    {...props}
    onClick={onClickButton(props.name, onClick, formValid)}
    data-test-id={`buttonLinkTransparent-${props.name}`}
    type={type || 'button'}
  >
    {children}
  </ButtonLinkTransparentStyled>
)
