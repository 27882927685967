import styled from 'styled-components'
import { space } from 'styled-system'

interface HeaderContainerProps {
  centered: boolean
}

export const HeaderContainer = styled.div.attrs({
  padding: ['0 1.5rem 0 0', null, null, '0']
})<HeaderContainerProps>`
  ${space};
  width: ${({ centered }) => (centered ? 'auto' : '100%')};
  max-width: 78.1rem;
  display: flex;
  justify-content: ${({ centered }) => (centered ? 'center' : 'space-between')};
`
