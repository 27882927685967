import React, { FC, memo, useEffect, useState } from 'react'
import { NumberFormatValues, NumberFormatProps } from 'react-number-format'

import { InputBase, InputBaseProps } from '../../base/input-base'

import { InputSmsStyled } from './input-sms-styled'

export type InputSmsViewType = InputBaseProps & Partial<NumberFormatProps>

const Input = InputBase<Partial<NumberFormatProps>>(InputSmsStyled)

let timeoutRef: null | number = null
export const InputSmsView: FC<InputSmsViewType> = memo((props) => {
  const [type, setType] = useState<'password' | 'tel'>('tel')

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      if (timeoutRef) {
        clearTimeout(timeoutRef)
      }
    }
  }, [])

  const handleTimeout = () => {
    if (timeoutRef) {
      clearTimeout(timeoutRef)
    }
    timeoutRef = setTimeout(() => {
      setType('password')
    }, 1000)
  }

  const onValueChange = ({ value }: NumberFormatValues) => {
    setType('tel')

    if (props.onChange) {
      props.onChange(value)
    }
    handleTimeout()
  }

  return (
    <Input
      {...props}
      allowEmptyFormatting
      mask='-'
      format='########'
      onValueChange={onValueChange}
      type={type}
      autoComplete='one-time-code'
      onFocus={() => {
        setType('tel')
        handleTimeout()
      }}
    />
  )
})
