import React from 'react'

import { onClickButton } from '../../../../utils'
import { ButtonProps } from '../components/button-styled'

import { ButtonPrimaryStyled } from './components/button-primary-styled'

export const ButtonPrimary: React.FC<ButtonProps> = ({ children, type, onClick, formValid, ...props }) => (
  <ButtonPrimaryStyled
    {...props}
    onClick={onClickButton(props.name, onClick, formValid)}
    data-test-id={`buttonPrimary-${props.name}`}
    type={type || 'button'}
  >
    {children}
  </ButtonPrimaryStyled>
)
