/* eslint-disable @typescript-eslint/no-explicit-any */
const getWebglInfo = () => {
  try {
    const ctx = document.createElement('canvas').getContext('webgl') || ({} as any)

    const result = {
      VERSION: ctx.getParameter(ctx.VERSION),
      SHADING_LANGUAGE_VERSION: ctx.getParameter(ctx.SHADING_LANGUAGE_VERSION),
      VENDOR: ctx.getParameter(ctx.VENDOR),
      SUPORTED_EXTENSIONS: ctx.getSupportedExtensions()
    }

    return result
  } catch {
    return null
  }
}

export const createFingerprint = () => {
  let { devicePixelRatio } = window || {}
  // weird behaviour when getting value from localhost vs ip!!!
  // eslint-disable-next-line radix
  devicePixelRatio = +parseInt(String(devicePixelRatio))

  const {
    appName,
    appCodeName,
    appVersion,
    cookieEnabled,
    doNotTrack,
    hardwareConcurrency,
    language,
    languages,
    maxTouchPoints,
    platform,
    product,
    productSub,
    userAgent,
    vendor,
    vendorSub,
    webdriver
  } = window.navigator

  const plugins = Object.entries(window.navigator.plugins).map(([, plugin]) => plugin.name)
  const mimeTypes = Object.entries(window.navigator.mimeTypes).map(([, mimeType]) => mimeType.type)

  const { width, height, colorDepth, pixelDepth } = window.screen
  const timezoneOffset = new Date().getTimezoneOffset()
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const touchSupport = 'ontouchstart' in window

  const webglInfo = getWebglInfo()

  const data = {
    devicePixelRatio,
    appName,
    appCodeName,
    appVersion,
    cookieEnabled,
    doNotTrack,
    hardwareConcurrency,
    language,
    languages,
    maxTouchPoints,
    mimeTypes,
    platform,
    plugins,
    product,
    productSub,
    userAgent,
    vendor,
    vendorSub,
    webdriver,
    width,
    height,
    colorDepth,
    pixelDepth,
    timezoneOffset,
    timezone,
    touchSupport,
    webglInfo
  }

  return JSON.stringify(data)
}
