import React, { FocusEvent, forwardRef } from 'react'
import styled from 'styled-components'

import { inputBaseStyledCss, InputBaseStyledProps } from '../../base/input-base-styled'

const InputDateCustomStyled = styled.input<InputBaseStyledProps>`
  ${inputBaseStyledCss}
`

interface InputDateCustom {
  active: boolean
  errors: Array<{ code: string; message: string }>
  showError?: boolean
  value?: string
  placeholder?: string
  onFocusHandler?: (event: FocusEvent) => void
  onBlurHandler?: (event: FocusEvent) => void
  onFocus?: (event: FocusEvent) => void
  onBlur?: (event: FocusEvent) => void
  warn?: boolean
}

const charsetModifier = (charset: RegExp) => (value: string) => value
  .split('')
  .filter((char) => charset.test(char))
  .join('')

const maxLengthModifier = (maxLength: number) => (value: string) => value.split('').slice(0, maxLength).join('')

export const InputDateCustom = forwardRef<HTMLInputElement, InputDateCustom>((
  { onBlurHandler, onFocusHandler, warn, ...rest },
  ref
) => (
  <InputDateCustomStyled
    {...rest}
    value={rest.value ? maxLengthModifier(10)(charsetModifier(/^[0-9.]*$/)(rest.value)) : rest.value}
    maxLength={10}
    type='text'
    ref={ref}
    onBlur={(e) => {
      onBlurHandler && onBlurHandler(e)
      rest.onBlur && rest.onBlur(e)
    }}
    onFocus={(e) => {
      onFocusHandler && onFocusHandler(e)
      rest.onFocus && rest.onFocus(e)
    }}
    warn={warn}
  />
))
