import React, { FC } from 'react'

import { Text } from '../root'

import { SubTitleStyled } from './components/title-styled'

export const SubTitle: FC = ({ children }) => (
  <SubTitleStyled>
    <Text>{children}</Text>
  </SubTitleStyled>
)
