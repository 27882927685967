import { motion } from 'framer-motion'
import styled from 'styled-components'

export const ContentMotion = styled(motion.div).attrs(() => ({
  initial: 'collapsed',
  animate: 'open',
  inherit: false,
  exit: 'collapsed',
  variants: {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 }
  },
  transition: { duration: 0.25, ease: 'easeOut' }
}))`
  overflow: hidden;
`
