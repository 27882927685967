/* eslint-disable no-console */
import configurationDefault from './configuration.json'

export const fetchConfiguration = async() => {
  const production = process.env.NODE_ENV === 'production'
  const response = await fetch('/api/configuration')

  if (production) {
    if (response.status === 200) {
      const data = await response.json()

      if (data) {
        return data
      }
    }

    console.error('Configuration json file was not loaded.')
    console.error('This site will not work properly!!!')
  } else {
    return configurationDefault
  }

  return {}
}
