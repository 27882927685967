export const scrollToId = (id: string) => {
  const elem: HTMLElement | null = document.getElementById(id)

  if (elem && elem.ownerDocument && elem.parentElement) {
    const rect = elem.getBoundingClientRect()
    const win = elem.ownerDocument.defaultView

    if (win) {
      const offset = rect.top + win.pageYOffset
      const wrapper = elem.parentElement.parentElement

      if (wrapper) {
        window.scrollTo(0, offset - wrapper.getBoundingClientRect().height)
      }
    }
  }
}
