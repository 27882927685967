import React from 'react'

import { InfoStepsItemImg, InfoStepsItemImgProps } from './components/img/info-steps-item-img'
import { InfoStepsItemStyled } from './components/info-steps-item-styled'
import { InfoStepsItemTxt } from './components/txt/info-steps-item-txt'

export interface InfoStepsItemProps extends InfoStepsItemImgProps {
  text: string
}

export const InfoStepsItem = ({ text = '', imgSrc, imgAlt, width, height }: InfoStepsItemProps) => (
  <InfoStepsItemStyled>
    <InfoStepsItemImg
      imgSrc={imgSrc}
      imgAlt={imgAlt}
      width={width}
      height={height}
    />
    <InfoStepsItemTxt
      text={text}
    />
  </InfoStepsItemStyled>
)
