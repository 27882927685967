import styled from 'styled-components'

import { buttonDownloadTextActiveColor, buttonDownloadTextColor, fontMedium } from '../../../../../theme'

interface TextWrapperProps {
  hover?: boolean
}

export const TextWrapper = styled.div<TextWrapperProps>`
  display: flex;
  padding: 2rem;
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${({ hover }) => (hover ? buttonDownloadTextActiveColor : buttonDownloadTextColor)};
  ${fontMedium};
  align-items: center;
  transition: color 150ms linear;
  text-decoration: underline;
`
