import styled, { css } from 'styled-components'

import { fontMedium, headerListItemDisabledColor, headerListItemColor } from '../../../../theme'

export interface HeaderListItemProps {
  active: boolean
  passed: boolean
  hidden: boolean
}

export const HeaderListItem = styled.li<HeaderListItemProps>`
  margin: 0;
  padding: 1.8rem 0 1.5rem 2rem;
  line-height: 2.2rem;
  font-size: 1.4rem;
  vertical-align: bottom;
  color: ${headerListItemColor};
  position: relative;

  ${({ active }) => active && css`
      + li,
      + li + li,
      + li + li + li,
      + li + li + li + li {
        color: ${headerListItemDisabledColor};
      }
  `};

  @media (max-width: 550px) {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }

  @media (max-width: 1024px) {
    ${({ active }) => !active && css`
      position: absolute;
      left: -50px;
      top: -50px;
      opacity: 0;
      pointer-events: none;
      white-space: nowrap;
    `};
  }

  &:before {
    counter-increment: section;
    content: counter(section) ". ";
    font-family: 'Geometria';
    ${fontMedium};
    font-size: 1.5rem;
    top: 0;
    margin-right: 0.4rem;

    ${({ passed }) => passed && css`
      font-family: jat;
      font-weight: 400;
      text-transform: none;
      content: "\\6c";
      font-size: 1.6rem;
      line-height: 1.2rem;
      position: relative;
      top: 2px;
    `}
  }
`
