import React, { FC, ReactNode } from 'react'

import { FooterTextStyled } from './components/footer-text-styled'

export interface CaptchaTextProps {
  children: ReactNode
}

export const FooterText: FC<CaptchaTextProps> = ({ children }) => (
  <FooterTextStyled>
    {children}
  </FooterTextStyled>
)
