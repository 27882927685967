import styled from 'styled-components'

export const BannerWrapper = styled.div<{ type: 'error' | 'info' }>`
  display: flex;
  align-items: center;
  min-height: 7rem;
  border: none;
  padding: 1rem;
  background: ${({ type }) => (type === 'error' ? '#EED1D3' : '#DBEBFF')};
`
