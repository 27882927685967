import { withDefaultProps, Input as CoreInput } from 'core-ui'
import { FC } from 'react'

import { InputBaseProps } from '../base/input-base'

import { InputView } from './components/input-view'

export const Input: FC<InputBaseProps> = withDefaultProps({
  RenderComponent: InputView
}, CoreInput)
