import { Translations } from '@jtb-don-fe/types'
import React, { FC } from 'react'

import { ButtonGroup } from '../../../../layout/button-group'
import { ButtonPrimary } from '../../../button/primary'
import { ButtonSecondary } from '../../../button/secondary'
import { DragAndDropType } from '../../root'

interface PreviewButtonsProps {
  setType: (value: DragAndDropType) => void
  setValue: (value?: File) => void
  onClick: () => void
  onMobileReset: () => void
  translations: Translations
}

export const PreviewButtons: FC<PreviewButtonsProps> = (
  { setType, setValue, onClick, onMobileReset, translations }
) => {
  const onReset = () => {
    setValue(undefined)
    setType(DragAndDropType.Upload)
    onMobileReset()
  }

  return (
    <ButtonGroup>
      <ButtonSecondary
        onClick={onReset}
        name='cancel'
      >
        {translations.button.preview.cancel}
      </ButtonSecondary>
      <ButtonPrimary
        onClick={onClick}
        name='submit'
      >
        {translations.button.preview.submit}
      </ButtonPrimary>
    </ButtonGroup>
  )
}
