import styled from 'styled-components'

export const InfoStepsItemStyled = styled.div`
  flex: 0 0 33.33%;
  padding: 0 1.5rem;
  margin: 1.5rem 0;
  max-width: 33.33%;

  @media (max-width: 767px) {
    padding: 1.5rem 1.5rem 0.5rem;
    flex: 0 0 100%;
    max-width: 100%;
  }
`
