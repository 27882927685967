import { FC } from 'react'
import styled from 'styled-components'

import { Grid, GridType } from '../../grid'

interface SideMarginWrapperProps extends GridType {
  multiColumns?: boolean
}

export const SideMarginWrapper: FC<SideMarginWrapperProps> =
  styled(Grid).attrs(({ multiColumns }: SideMarginWrapperProps) => ({
    justifyContent: ['stretch', null, 'flex-end'],
    gridTemplateColumns: multiColumns ? ['auto', null, 'repeat(auto-fit, minmax(10rem, 1fr))'] : ['auto', null, '50%'],
    gridGap: '1.5rem',
    paddingTop: '1rem'
  }))``
