import React, { FC } from 'react'
import { components, ValueContainerProps } from 'react-select'

import { OptionType } from '../../select'

import { SelectValueContainerStyled } from './select-value-container-styled'

export const SelectValueContainer: FC<ValueContainerProps<OptionType>> = ({ children, ...props }) => (
  <SelectValueContainerStyled
    toggleable={props.selectProps.toggleable}
    isSearchable={props.selectProps.isSearchable}
  >
    <components.ValueContainer
      {...props}
    >
      {children}
    </components.ValueContainer>
  </SelectValueContainerStyled>
)
