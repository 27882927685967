import { FC, MouseEvent } from 'react'
import styled, { css } from 'styled-components'

import {
  buttonLinkTransparentTextColor, buttonLinkTransparentBackgroundColor, buttonLinkTransparentTextActiveColor,
  buttonLinkTransparentBorderActiveColor, buttonLinkTransparentBackgroundActiveColor
} from '../../../../../../theme'
import { ButtonStyled } from '../../../components/button-styled'

interface ButtonLinkTransparentStyledProps {
  type?: string
  onClick?: (event: MouseEvent) => void
}

export const ButtonLinkTransparentStyled: FC<ButtonLinkTransparentStyledProps> = styled(ButtonStyled)`
  border: 1px solid transparent;
  color: ${buttonLinkTransparentTextColor};
  background-color: ${buttonLinkTransparentBackgroundColor};
  transition: background-color 150ms linear, color 150ms linear, opacity 150ms linear;
  text-decoration: underline;

  ${({ disabled }) => !disabled && css`
    &:hover {
      background-color: ${buttonLinkTransparentBackgroundActiveColor};
      border: 1px solid ${buttonLinkTransparentBorderActiveColor};
      color: ${buttonLinkTransparentTextActiveColor};
    }
  `}
`
