import React from 'react'

import { InfoStepsItemImgStyled } from './components/info-steps-item-img-styled'

export interface InfoStepsItemImgProps {
  imgSrc: string
  imgAlt: string
  width: number
  height: number
}

export const InfoStepsItemImg = ({ imgSrc, imgAlt, width, height }: InfoStepsItemImgProps) => (
  <InfoStepsItemImgStyled>
    <img
      src={imgSrc}
      alt={imgAlt}
      width={width}
      height={height}
    />
  </InfoStepsItemImgStyled>
)
