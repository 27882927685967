import styled from 'styled-components'

import { headerBackgroundColor } from '../../../../theme'

export const HeaderStyled = styled.div`
  justify-content: center;
  display: flex;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  background-color: ${headerBackgroundColor};
`
