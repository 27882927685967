import styled from 'styled-components'
import { space } from 'styled-system'

import { layoutColor } from '../../../../theme'

export const LayoutStyled = styled.div.attrs({
  paddingTop: ['6rem', null, '0'],
  paddingBottom: [null, null, '9rem', null, '0']
})`
  ${space};
  min-height: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  color: ${layoutColor};
  justify-content: stretch;
  position: initial;
  width: 100%;
`
