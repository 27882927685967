import styled from 'styled-components'

import { tooltipBackgroundColor, tooltipTextColor } from '../../../../../../theme'

export const ContentWrapper = styled.div`
  color: ${tooltipTextColor};
  background-color: ${tooltipBackgroundColor};
  border-radius: 0.4rem;
  position: relative;
  padding: 2.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1.3rem;

  &:before {
    display: block;
    content: ' ';
    position: absolute;
    margin-left: -0.6rem;
    margin-top: -0.6rem;
    width: 1.2rem;
    height: 1.2rem;
    transform: rotate(45deg);
    z-index: 1;
    background-color: ${tooltipBackgroundColor};
    left: 50%;
    top: 0;
  }

  ol {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      list-style: none;
      position: relative;
      padding: 0 0 0 2rem;
      margin: 1.5rem 0;
      font-size: 1.2rem;
      line-height: 2rem;
      text-align: left;
      letter-spacing: 1px;

      &:before {
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    &.numeric {
      counter-reset: numeric;

      > li:before {
        counter-increment: numeric;
        content: counter(numeric, decimal)". "
      }
    }

    &.alpha {
      counter-reset: alpha;

      > li:before {
        counter-increment: alpha;
        content: counter(alpha, lower-alpha)") "
      }
    }
  }
`
