import * as React from 'react'

export const ErrorIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.0007 10.9333V7.81665'
      stroke='#081E1D'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.99982 13.4375C9.88482 13.4375 9.79149 13.5309 9.79232 13.6459C9.79232 13.7609 9.88565 13.8542 10.0007 13.8542C10.1157 13.8542 10.209 13.7609 10.209 13.6459C10.209 13.5309 10.1157 13.4375 9.99982 13.4375'
      stroke='#081E1D'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.69 3.44916L18.0725 14.6192C18.8142 15.9175 17.8767 17.5333 16.3817 17.5333H3.61665C2.12082 17.5333 1.18332 15.9175 1.92582 14.6192L8.30832 3.44916C9.05582 2.13999 10.9425 2.13999 11.69 3.44916Z'
      stroke='#081E1D'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
