import React, { CSSProperties, FC } from 'react'

import { Text } from '../root'

import { TitleStyled } from './components/title-styled'

interface Title extends Pick<CSSProperties, 'textAlign' | 'color'> {
  children: string
}

export const Title: FC<Title> = ({ children, textAlign, color }) => (
  <TitleStyled color={color} textAlign={textAlign}>
    <Text>{children}</Text>
  </TitleStyled>
)
