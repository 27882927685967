import { pushButtonClicked } from '@jtb-don-fe/utils'
import { MouseEvent } from 'react'

export type OnClickButtonEvent = MouseEvent

export const onClickButton = (buttonName: string, onClick?: (event: OnClickButtonEvent) => void, formValid?: boolean) =>
  (event: OnClickButtonEvent) => {
    if (formValid === undefined || formValid) {
      pushButtonClicked(buttonName)
    }

    if (event.preventDefault) {
      event.preventDefault()
    } else {
      new Event('buttonEvent').preventDefault()
    }

    if (onClick) {
      onClick(event)
    }
  }
