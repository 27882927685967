import React from 'react'

import { Text } from '../../text'
import { Flex } from '../flex'

import { BannerWrapper } from './banner-notification-styles'
import { InfoIcon } from './info-icon'
import { ErrorIcon } from './error-icon'

export interface BannerNotificationProps {
  message: string
  type: 'error' | 'info'
}

export const BannerNotification = ({ message, type }: BannerNotificationProps) => (
  <BannerWrapper type={type}>
    <Flex alignItems='center'>
      {type === 'error' ? <ErrorIcon /> : <InfoIcon />}
      <Text pl={3} textAlign='left'>
        {message}
      </Text>
    </Flex>
  </BannerWrapper>
)
