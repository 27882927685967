import React, { FC, ReactNode } from 'react'

import { FooterText } from './components/footer-text'
import { FormBox, FormBoxProps } from './components/form-box/form-box'
import { LayoutStyled } from './components/layout-styled'
import { LayoutTop } from './components/layout-top-styled'

export interface LayoutProps extends FormBoxProps {
  showFooter?: boolean
  footerHelp?: ReactNode
}

export const Layout: FC<LayoutProps> = ({ children, showFooter = true, footerHelp, wideLayout, fullHeight }) => (
  <LayoutStyled>
    <LayoutTop>
      <FormBox
        fullHeight={fullHeight}
        wideLayout={wideLayout}
      >
        {children}
      </FormBox>
    </LayoutTop>

    {showFooter && footerHelp && (
      <FooterText>
        {footerHelp}
      </FooterText>
    )}
  </LayoutStyled>
)
