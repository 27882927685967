import styled, { keyframes } from 'styled-components'

import { loaderItemBackgroundColor } from '../../../../theme'

const rotate = keyframes`
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
`

export const LoaderItemStyled = styled.div`
  left: 5.642rem;
  top: 3.038rem;
  position: absolute;
  animation: ${rotate} linear 1s infinite;
  background: ${loaderItemBackgroundColor};
  width: 1.116rem;
  height: 1.116rem;
  border-radius: 0.558rem;
  transform-origin: 0.558rem 3.162rem;
  box-sizing: content-box;

  &:nth-child(1) {
    transform: rotate(0);
    animation-delay: -.8888888888888888s;
  }

  &:nth-child(2) {
    transform: rotate(40deg);
    animation-delay: -.7777777777777778s;
  }

  &:nth-child(3) {
    transform: rotate(80deg);
    animation-delay: -.6666666666666666s;
  }

  &:nth-child(4) {
    transform: rotate(120deg);
    animation-delay: -.5555555555555556s;
  }

  &:nth-child(5) {
    transform: rotate(160deg);
    animation-delay: -.4444444444444444s;
  }

  &:nth-child(6) {
    transform: rotate(200deg);
    animation-delay: -.3333333333333333s;
  }

  &:nth-child(7) {
    transform: rotate(240deg);
    animation-delay: -.2222222222222222s;
  }

  &:nth-child(8) {
    transform: rotate(280deg);
    animation-delay: -.1111111111111111s;
  }

  &:nth-child(9) {
    transform: rotate(320deg);
    animation-delay: 0s;
  }
`
