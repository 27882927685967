import styled from 'styled-components'
import { flexbox } from 'styled-system'

export const WrapperStyled = styled.div.attrs({
  flexDirection: ['column', 'column', 'row']
})`
  ${flexbox};
  margin: 2.5rem -1.5rem 0;
  display: flex;
`
