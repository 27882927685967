import styled from 'styled-components'

import { dragAndDropPlaceholderColor } from '../../../../../theme'

export const Placeholder = styled.div`
  color: ${dragAndDropPlaceholderColor};
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  padding: 3rem;
  text-align: center;
`
